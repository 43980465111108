import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Items.module.sass";
import Card from "../../../components/Card";
import Loader from "../../../components/Loader";
import {
  getMyOwnedAssets,
  GetOwnedAssetsByAlchemy,
} from "../../../services/ApiServices";
import Card2 from "../../../components/Card2";
import { useWeb3React } from "@web3-react/core";
import moment from "moment";
import { ContactsOutlined } from "@ant-design/icons";
import { useSnackbar } from "notistack";

const Items = ({ className, items, isLoading }) => {
  const { account,active } = useWeb3React();
  const navLinks = ["Collected"];
  const [activeIndex, setActiveIndex] = useState(0);
  const [assets, setAssets] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [fullPortfolio, setFullPortfolio] = useState([]);
  const [viewAllAssest, setViewAllAssets] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [page, setPage] = useState(1);
  const [tokenUrl, setTokenUrl] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    // getAssetspaginate(account)
  }, [account]);
  let address = account;

  const getOwnedAssets = async () => {
    setIsLoading(true);
    let result;
    if (!active) {
      return enqueueSnackbar("Please connect your wallet", {
        variant: "error",
      });
    } else {
      try {
        result = await GetOwnedAssetsByAlchemy(address);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        return console.log("Error while fetch my assets api...", error);
      }

      //   let sort=result.ownedNfts.sort((a, b) =>
      //   new Date(b.contractMetadata.openSea.lastIngestedAt) - new Date(a.contractMetadata.openSea.lastIngestedAt),
      // );
      setFullPortfolio(result.ownedNfts);
    }
    // console.log(sort)
  };

  useEffect(() => {
    if (active) {
      getOwnedAssets(account);
    }
    // else{
    //   return enqueueSnackbar(
    //     "Please connect your wallet",
    //     { variant: "error" }
    //   );
    // }
  }, [account, page,active]);

  return (
    <div className={cn(styles.items, className)}>
      <div className={styles.nav}>
        {navLinks.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => {
              setActiveIndex(index);
            }}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      {loading ? (
        <Loader className={styles.loader} />
      ) : (
        <>
          <div className={styles.list}>
            {fullPortfolio
              // .sort((a, b) => moment(a.contractMetadata.openSea.lastIngestedAt).format("DD/MM/YYYY") - moment(b.contractMetadata.openSea.lastIngestedAt).format("DD/MM/YYYY"))
              .map((x, index) =>
                viewAllAssest ? (
                  <Card2 className={styles.card} item={x} key={index} />
                ) : (
                  index < 6 && (
                    <Card2 className={styles.card} item={x} key={index} />
                  )
                )
              )}
          </div>
          {/* {fullPortfolio.length>0 &&
      <div className={styles.pagination}>
                  <Pagination current={page} onChange={(page) => {setPage(page);}} total={totalDocs} defaultPageSize={6} />
                    </div>} */}
          {fullPortfolio.length > 6 && (
            <button
              className={cn("button", styles.show)}
              onClick={() => setViewAllAssets(!viewAllAssest)}
            >
              {" "}
              {viewAllAssest ? "View Less" : "View More"}{" "}
            </button>
          )}
          {!loading && !fullPortfolio.length && !active  && (
            <h3 className={styles.nodata}>No Assets Available</h3>
          )}
        </>
      )}
    </div>
  );
};

export default Items;
