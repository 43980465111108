
import React from "react";
import Hero from "./Hero";
import HotBid from "../../components/HotBid";
import cn from "classnames";
import styles from "./Faq.module.sass";

const Faq = () => {
  return (
    <>
    <div className={cn("container", styles.container)}>
      <h3 className={cn("h4", styles.title)}>FAQ</h3>
      <div className={styles.text}>
      </div>
      <div className={styles.content}>
            <h3>NFTs and how to collect them :</h3>
              <h5>1. What is an NFT</h5>
              <p>An NFT or non fungible token is a digital asset with a unique record on the blockchain</p>
              <h5>2. What kinds of NFTs are available on the marketplace</h5>
              <p>NFTs such as digital posters, images, face filters, AR avatars, virtual backgrounds, audio and video snippets, are available now or will be available later on the marketplace</p>
              <h5>3. Do we need a wallet to purchase and/or collect NFTs</h5>
              <p>Yes, a wallet is needed. We are currently supporting the MetaMask wallet&nbsp;</p>
              <h5>4. How do I connect my wallet&nbsp;</h5>
              <p>Its extremely simple. Just use the &ldquo;Connect&rdquo; button to connect your MetaMask wallet. You need to have a MetaMask wallet before trying to connect the wallet&nbsp;</p>
              <h5>5. What if I don&rsquo;t have a MetaMask wallet&nbsp;</h5>
              <p>Its super easy to create one at <a data-fr-linked="true" href="//www.metamask.io">www.metamask.io</a></p>
              <p><br/></p>
              <h3>Using the Marketplace :</h3>
              <h5>1. How can I start using the marketplace&nbsp;</h5>
              <p>Once you have connected your MetaMask wallet, the next step is to register. Once you have registered, you can start using the marketplace</p>
              <h5>2. What are collections</h5>
              <p>Collections are a set of related NFTs.&nbsp;</p>
              <h5>3. How do I know the details of an NFT</h5>
              <p>Clicking on an NFT will show the NFT details and attributes</p>
              <h5>4. How do I know what I have purchased</h5>
              <p>Your purchases are shown in the &ldquo;My account&rdquo; section under your wallet address</p>
              <h5>5. How do I enjoy utilities on the NFTs that I have purchased</h5>
              <p>For using the utilities, you will have to download the wowTalkies app&nbsp;</p>
              <p><br/></p>
              <h3>Purchasing NFTs :</h3>
              <h5>1. How can I purchase an NFT</h5>
              <p>Please select the NFT that you wish to purchase. To purchase, please click &ldquo;Buy now&rdquo;</p>
              <h5>2. What currency do I use to purchase&nbsp;</h5>
              <p>The NFTs are priced in Matic.&nbsp;</p>
              <h5>3. Who pays the Gas fees</h5>
              <p>The gas fees are paid by the purchaser. The final amount that is payable includes the gas fees. The exact gas fees, in Matic, will only be known at the time of minting the NFT</p>
              <h5>4. Do I need to complete KYC</h5>
              <p>&nbsp;KYC is mandatory for Indian users to purchase NFTs in the marketplace</p>
              <h5>5. Are there any charges for purchase of Matic</h5>
              <p>Yes. The purchase of Matic includes transaction charges for the payment gateway.</p>
              <h5>6. How do I transfer Matic to my Meta Mask wallet</h5>
              <p>We have integrated a payment gateway (from On Meta), that allows you to purchase Matic. The Matic purchased through the payment gateway is automatically transferred to the connected Meta Mask wallet</p>
              <h5>7. What does the final price, at the time of minting, include</h5>
              <p>The final price includes the a) sale price and b) gas fees</p>
              <p><br/></p>
              <h3>General FAQs :</h3>
              <h5>1. What do I do if I need help and support&nbsp;</h5>
              <p>Please write to <a data-fr-linked="true" href="mailto:support@wowtalkies.com">support@wowtalkies.com</a>, and we promise to revert at the earliest</p>
              <h5>2. Why do I need to submit KYC information</h5>
              <p>wowTalkies is mandated, by law, to collect and validate KYC details, for Indian nationals</p>
              <h5>3. What if I need to return the NFTs, after purchase</h5>
              <p>NFTs are irrevocable records on the blockchain. NFTs hence do not have a return or replace policy&nbsp;</p>
              <h5>4. Which blockchain is wowTalkies using&nbsp;</h5>
              <p>We are on Polygon. Polygon is the leading L1 blockchain</p>
              <h5>5. My NFT is taking too long to mint</h5>
              <p>In case of congestion on the chain, there could be delays in minting. These are however rare occurrences</p>
              <h5>6. How can I check transaction records</h5>
              <p>Transaction records are available on chain, and can be checked on Polygonscan</p>
              <h5>7. I have minted the NFT, it is however not available in my account</h5>
              <p>Please check for the transaction record on Polygonscan. In rare cases, the transaction might take time to reflect in the &ldquo;My account&rdquo; page</p>
              <h5>8. I have minted and waited, however the NFT is not available in my account&nbsp;</h5>
              <p>Please write to us at <a data-fr-linked="true" href="mailto:support@wowtalkies.com">support@wowtalkies.com</a>, and we will investigate and revert</p>
              <h5>9. Does wowTalkies support additional currencies</h5>
              <p>We only support Matic as of now, but will support more currencies as we progress</p>
              <h5>10. My bank account is debited, but my purchase of Matic does not reflect in the Meta Mask wallet&nbsp;</h5>
              <p>The On Meta payment gateway is a third-party service that we are using. In the rarest of rare cases, if the purchased Matic does not reflect, please write to us at <a data-fr-linked="true" href="mailto:support@wowtalkies.com">support@wowtalkies.com</a>, and we will speak to On Meta on your behalf.&nbsp;</p>
              <p><br/></p>
      </div>
      </div>
      {/* <Hero />
      <HotBid classSection="section-pb" /> */}
    </>
  );
};

export default Faq;