import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import Icon from "../Icon";
import Image from "../Image";
import User from "./User";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../redux"; 
import * as AWS from "@aws-sdk/client-elasticsearch-service";
import firebase from '../../services/firebaseServices'
// import {GetUser} from '../../services/ApiServices'
const client = new AWS.ElasticsearchService({ region: "us-east-1" });

const nav = [
  {
    url: "/",
    title: "Home",
  },
  {
    url: "/Explore",
    title: "Explore",
  },
  {
    url: "/faq",
    title: "How it work",
  },
  {
    url: "/item/:id",
    title: "Create item",
  },
  {
    url: "/profile/:uid",
    title: "Profile",
  },
];

const Headers =  (props) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [callApi,setCallApi] =useState(false)
  const [visibleNav, setVisibleNav] = useState(false);
  const [search, setSearch] = useState("");
  const [isLogin,setIsLogin] = useState(false)
  const [user,setUser] = useState({})
  // if (state.Posts.isSearchLoaded === false) {
  //   dispatch(getSearch());
  // }
  // if (state.Posts.isFirstLoaded === false) {
  //   dispatch(getPosts());
  // }
  useEffect(() => {
  }, [state.Posts.posts]);
  useEffect(() => {
  }, [state.Posts.searchposts]);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.history.push('/Explore?search='+search)
  };
  const userDetails=async(uid)=>{ 
    dispatch(getUser(uid)) 
  }
  // const GetUser=()=>{
  //   firebase.auth().onAuthStateChanged(function(user) {
  //         if(user){
  //     setIsLogin(true)
  //     userDetails(user.uid)
  //   }
  //   else{
  //     setIsLogin(false)
  //     setUser()
  //   }
  //   });

  // }

  //   useEffect(async()=>{
  //     GetUser()
  //   },[])

  return (
    <header className={styles.header}>
      <div className={cn("container", styles.container)}>
        <Link className={styles.logo} to="/">
          <Image
            style="webkit-user-select:none;margin:auto;background-color:none;margin:auto;background-color:hs1(0,0%,90%);transition:background-color 300ms;"
            src="https://static.wixstatic.com/media/8c2a7e_2c21add094254ba7ae69ef8cc92afd66~mv2.png/v1/fill/w_84,h_83,al_c,q_85,usm_0.66_1.00_0.01/OKTOCHAIN%20RED.webp"
          />
        </Link>
       <div className={styles.user}>
        <User  user={user}  />
        </div>
        <button
          className={cn(styles.burger, { [styles.active]: visibleNav })}
          onClick={() => setVisibleNav(!visibleNav)}
        ></button>
      </div>
    </header>
  );
};

export default Headers;
