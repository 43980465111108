
import {
    MODALOPEN,
    MODALCLOSE,
    STOREPATH
} from './LocationTypes'

const initialState = {
    isModalLogin: false,
    locationPath: ''
}



const LocationReducer = (state = initialState, action) => {

    switch (action.type) {


        case MODALOPEN:

            return { ...state, isModalLogin: true }
        case MODALCLOSE:

            return { ...state, isModalLogin: false }
        case STOREPATH:

            return { ...state, locationPath: action.payload }


        default: return state;
    }



}

export default LocationReducer