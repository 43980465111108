import React, { useState } from "react";
import cn from "classnames";
import styles from "./Users.module.sass";
import { Link } from "react-router-dom";
import eth from "../../../assets/eth.png"
import { Row, Col } from "antd";
import {
  ShoppingCartOutlined,
  CopyOutlined,
  CopyFilled,
} from "@ant-design/icons";
import { Collapse } from "antd";
import { Table } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import './user.css'
// import useDarkMode from "use-dark-mode";

const { Panel } = Collapse;

const Users = ({ className, items, nav }) => {
  // const darkMode = useDarkMode(false);
  const [copy, setCopy] = useState(false);


  function callback(key) {
    console.log(key);
  }
  return (
    <div className={cn(styles.users, className)}>
           
      <Collapse defaultActiveKey={["1","2","3"]} onChange={callback} expandIconPosition="right" className={styles.collapse}>
        {/* <Panel className={darkMode.value?"panel":"panelDark"} header={(<b className={styles.field}><i className="fa fa-users" style={{ color: "black",fontSize:"20px",marginRight:"10px"}} />Issuer</b>)} key="1">
          <div>
            <Link to={"/profile/" + items.userId.id}>
              <div className={styles.avatar}>
                <img style={{height:"48px", width:"48px"}} src={items.userId.imageUrl} alt="Avatar" />
                <div className={styles.field1}>{items.userId.userName}</div>
              </div>
            </Link>
            <div className={styles.details}> */}
              {/* <div className={styles.position}>Group</div> */}
              {/* <div className={styles.field1}>{items.userId.userName}</div> */}
            {/* </div>
          </div>
        </Panel> */}
        {/* <Panel className={darkMode.value?"panel":"panelDark"} header={(<b className={styles.field}><i className="fa fa-info-circle" style={{ color: "black",fontSize:"18px",marginRight:"10px"}} />Info</b>)} key="3"> */}
          <div className={styles.chainInfo}>
            <Row className={styles.item}>
              <img style={{height:"30px",width:"20px"}} src={eth} alt=""/>
              <Col className={styles.name}>{items.blockchainName}</Col>
            </Row>
            <Row className={styles.item}>
              <Col span={10} className={styles.name}>
                Contract Type:
              </Col>
              <Col span={14}>{items.assetType}</Col>
            </Row>
            <Row className={styles.item}>
              <Col span={10} className={styles.name}>
                Contract Address:
              </Col>
              <Col span={14}>
              <a href={"https://etherscan.io/address/"+items.contractAddress} target="_blank" rel="noreferrer">
              { items.contractAddress ?		  
                      (   items.contractAddress.slice(0, 8) +
                       "...." +
                       items.contractAddress.toString().slice(-8) ) : "-"}
                </a>
                {/* <CopyToClipboard
                  text={items.contractAddress}
                  onCopy={() => setCopy(true)}
                >
                  <span>{copy ? <CopyFilled style={{ fontSize: '20px'}} /> : <CopyOutlined style={{ fontSize: '20px' }} />}</span> 
                </CopyToClipboard>*/}
              </Col>
            </Row>
            <Row className={styles.item}>
              <Col span={10} className={styles.name}>
                Token ID:
              </Col>
              <Col span={14}>{items.tokenId}</Col>
            </Row>
            {/* <Row className={styles.item}>
              <Col span={10} className={styles.name}>
                IPFS Path:
              </Col>
              <Col span={14}>{items.ipfsPath}</Col>
            </Row> */}
          </div>
        {/* </Panel> */}
      </Collapse>
  
    </div>
  );
};

export default Users;
