import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './AdminDashboard.module.sass';
import MobileData from '../../components/MobileData';
import WebData from '../../components/WebData';
import { GetAdmins } from '../../services/ApiServices';

const AdminDashboard = () => {
  const [AllowedEmails, setAllowedEmails] = useState([]);
  const [selectedOption, setSelectedOption] = useState('Mobile');

  useEffect(async () => {
    const result = await GetAdmins();
    if (result.success) {
      setAllowedEmails(result.admins.docs[0].emails);
    }
    console.log('admins ', result);
  }, [localStorage.getItem('email')]);

  // Function to update the selected option
  const handleButtonClick = (option) => {
    setSelectedOption(option);
  };

  return (
    <div>
      <div className={cn('container', styles.container)}>
        <h3 className={cn('h4', styles.title)}>Admin Dashboard</h3>
        <p className={styles.emailText}> {localStorage.getItem('email')}</p>
        {AllowedEmails.includes(localStorage.getItem('email')) ? (
          <div>
            <div className={styles.buttonContainer}>
              {/* Button for Mobile */}
              <button
                className={cn(styles.button, {
                  [styles.selected]: selectedOption === 'Mobile',
                })}
                onClick={() => setSelectedOption('Mobile')}
              >
                Mobile
              </button>

              {/* Button for Web */}
              <button
                className={cn(styles.button, {
                  [styles.selected]: selectedOption === 'Web',
                })}
                onClick={() => setSelectedOption('Web')}
              >
                Web
              </button>
            </div>

            {selectedOption === 'Mobile' && (
              <div className={styles.content}>
                {<MobileData onButtonClick={handleButtonClick} />}
              </div>
            )}
            {selectedOption === 'Web' && (
              <div className={styles.content}>{<WebData />}</div>
            )}
          </div>
        ) : (
          <h1>You don't have to access admin dashboard</h1>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
