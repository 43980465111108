import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import Theme from "../../Theme";
import Modal from "../../Modal";
import Dropdown from "../../Dropdown";
import {
  createBuyerUser,
  verifyBuyerAddress,
} from "../../../services/ApiServices";
import TextInput from "../../TextInput";
import firebase from "../../../services/firebaseServices";
import { useDispatch, useSelector } from "react-redux";

import { ethers } from "ethers";
import { getUser, getUserPosts } from "../../../redux";
import { PropertySafetyFilled } from "@ant-design/icons";

// const items = [
//   {
//     title: "My profile",
//     icon: "user",
//     url: "/profile/" + uid,
//   },
//   {
//     title: "My items",
//     icon: "image",
//     url: "/Explore",
//   },
//   {
//     title: "Dark theme",
//     icon: "bulb",
//   },
//   {
//     title: "Disconnect",
//     icon: "exit",
//   },
// ];

const options = ["MetaMask"];

const User = ({ className, refresh, userDetail, history }) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isSelectWallet, setIsSelectWallet] = useState(false);
  const [wallet, setWallet] = useState(options[0]);
  const [address, setAddress] = useState();
  const [balance, setBalance] = useState(0);
  const [user, setUser] = useState({});

  const items = [
    {
      title: "My profile",
      icon: "user",
      url: "/profile/" + user.id,
    },
    // {
    //   title: "My items",
    //   icon: "image",
    //   url: "/Explore",
    // },
    {
      title: "Dark theme",
      icon: "bulb",
    },
    {
      title: "Disconnect",
      icon: "exit",
    },
  ];
  useEffect(() => {
    setUser(state.User.user);
  }, [state.User.user]);
  
  if(user.id && !state.Posts.userPost.length){
    dispatch(getUserPosts(user.id))
  }
  
  const logout = () => {
    setVisible(false);
    firebase
      .auth()
      .signOut()
      .then(() => {
        refresh();
        setUser({});
        history.push('./login')
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.user, className)}>
        <div className={styles.head} onClick={() => setVisible(!visible)}>
          <div className={styles.avatar}>
            <img src={user.imageUrl} alt="Avatar" />
          </div>
          <div className={styles.walletname}>
            {userDetail.userName} <span className={styles.currency}></span>
          </div>
        </div>

        {visible && userDetail.userName && (
          <div className={styles.body}>
            <div className={styles.name}>{userDetail.userName}</div>
            <div className={styles.number}>{userDetail.email}</div>

            <div className={styles.menu}>
              {items.map((x, index) =>
                x.url ? (
                  x.url.startsWith("http") ? (
                    <a
                      className={styles.item}
                      href={x.url}
                      rel="noopener noreferrer"
                      key={index}
                    >
                      <div className={styles.icon}>
                        <Icon name={x.icon} size="20" />
                      </div>
                      <div className={styles.text}>{x.title}</div>
                    </a>
                  ) : (
                    user.id && (
                      <Link
                        className={styles.item}
                        to={x.url}
                        onClick={() => setVisible(!visible)}
                        key={index}
                      >
                        <div className={styles.icon}>
                          <Icon name={x.icon} size="20" />
                        </div>
                        <div className={styles.text}>
                          {x.title}
                          {}
                        </div>
                      </Link>
                    )
                  )
                ) : x.title == "Disconnect" ? (
                  <div
                    className={styles.item}
                    key={index}
                    onClick={logout}
                    style={{ cursor: "pointer" }}
                  >
                    <div className={styles.icon}>
                      <Icon name={x.icon} size="20" />
                    </div>
                    <div className={styles.text}>{x.title}</div>
                  </div>
                ) : (
                  <div className={styles.item} key={index}>
                    <div className={styles.icon}>
                      <Icon name={x.icon} size="20" />
                    </div>
                    <div className={styles.text}>{x.title}</div>
                    <Theme className={styles.theme} />
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
      
    </OutsideClickHandler>
  );
};

export default User;
