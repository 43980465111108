import React, { useState } from 'react';
import styles from './web.module.sass';
import Modal from '../Modal';
import { fetchSalesReportData } from '../../services/ApiServices';

const WebData = () => {
  const [showSalesReport, setShowSalesReport] = useState(false);
  const [reportSelectedDateRange, setReportSelectedDateRange] = useState({
    from: '',
    to: '',
  });
  const [reportErrormsg, setReportErrorMsg] = useState('');
  const [reportSuccessMessage, setReportSuccessMessage] = useState('');
  const [isGetReportDataButtonClicked, setIsGetReportDataButtonClicked] =
    useState(false);

  const handleSalesReportButtonClick = () => {
    setShowSalesReport(true);
  };

  const handleGetReportData = async () => {
    setIsGetReportDataButtonClicked(true);
    if (!reportSelectedDateRange.from || !reportSelectedDateRange.to) {
      setReportErrorMsg("Please provide both 'From Date' and 'To Date'.");
    } else {
      setReportErrorMsg(null); // Clear any previous error
      setReportSuccessMessage(
        'Your request is currently being processed. Once it is completed, you will receive an email.'
      );
      setIsGetReportDataButtonClicked(true);
      let body = {
        emailId: localStorage.getItem('email'),
        startTime: `${reportSelectedDateRange.from}T00:00:00Z`,
        endTime: `${reportSelectedDateRange.to}T23:59:59Z`,
      };
      const result = await fetchSalesReportData(body);
      if (result?.success) {
        setReportErrorMsg(null);

        // console.log('mobile data response is ', result);
        if (result?.salesReportData === 'Email sent successfully') {
          setReportSuccessMessage(
            `sales report data has been successfully delivered to your email`
          );
        } else if (
          result?.salesReportData ===
          'No data found for the specified date range'
        ) {
          setReportSuccessMessage('No data found for the specified date range');
        }
        setIsGetReportDataButtonClicked(false);
      } else {
        setReportSuccessMessage(null);
        setReportErrorMsg('Something went wrong');
        setIsGetReportDataButtonClicked(false);
      }
    }
  };

  return (
    <>
      <div className={styles.buttonGrid}>
        <div>
          <button
            className={styles.button}
            onClick={handleSalesReportButtonClick}
          >
            Sales Report Data
          </button>
        </div>
        <Modal
          visible={showSalesReport}
          onClose={() => setShowSalesReport(false)}
          outerClassName={styles.deleveryModal}
        >
          <div className={`${styles.analyticsData} modalContent`}>
            <h3>Sales Report Data</h3>
            <label>
              <b>From Date</b>
            </label>
            <input
              className={styles.leftColumn}
              type="date"
              value={reportSelectedDateRange.from}
              onChange={(e) =>
                setReportSelectedDateRange({
                  ...reportSelectedDateRange,
                  from: e.target.value,
                })
              }
            />
            <label>
              <b>To Date</b>
            </label>
            <input
              className={styles.leftColumn}
              type="date"
              value={reportSelectedDateRange.to}
              onChange={(e) =>
                setReportSelectedDateRange({
                  ...reportSelectedDateRange,
                  to: e.target.value,
                })
              }
            />
            {isGetReportDataButtonClicked ? (
              ''
            ) : (
              <button onClick={handleGetReportData}>
                <b>Get Data</b>
              </button>
            )}
            {reportErrormsg && (
              <div className={styles.alert}>{reportErrormsg}</div>
            )}
            {reportSuccessMessage && (
              <div className={styles.success}>{reportSuccessMessage}</div>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default WebData;
