import React, { useEffect, useState } from "react";
import Card from "../Card";
import cn from "classnames";
import styles from "./ForgotPassword.module.sass";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Loader from "../../../components/Loader";
import Modal from "../../../components/Modal";
import firebase from "../../../services/firebaseServices";
import CreatableSelect from "react-select/creatable";
import { PostAsset } from "../../../services/ApiServices";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Link } from "react-router-dom";

function ForgotPassword(props) {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const [file, setFile] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");

  const [selected, setSelected] = useState([]);
  const [emailError,setEmailError] = useState("")
  const [passwordError,setPasswordError] = useState("")

  const emailFunction = async (e) => {
    setError("")
    setEmailError("");
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
        setEmail("")
      setEmailError("Please enter the valid Email");
    } else {
      setEmailError("");
      setEmail(e.target.value);
    }
  };
  const passwordFunction = (e) => {
    setError("")
      if(e.target.value.length<8)
      setPasswordError("Password Should be 8 characters")
      else{
    setError("");
    setPasswordError("")
    setPassword(e.target.value);
}
  };

  const createItem = () => {
    if (!email) setError("Email is required!");
    else if (!password) setError("Password is required!");
    //   else
    //   upload()
  };
  const createPost = async (url) => {
    var tags = [];
    selected.map((data) => {
      tags.push(data.label);
    });

    let result;
    let body = {
      email: email,
      password: password,
    };
    try {
      result = await PostAsset(body);
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        setEmail("");
        setPassword("");
        console.log(result);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      <Card>
        <div className={cn("section", styles.section)}>
          <div className={cn("container", styles.container)}>
            <div className={styles.wrapper}>
              <img
                className={styles.image}
                src="https://static.wixstatic.com/media/8c2a7e_2c21add094254ba7ae69ef8cc92afd66~mv2.png/v1/fill/w_84,h_83,al_c,q_85,usm_0.66_1.00_0.01/OKTOCHAIN%20RED.webp"
              />

              <form className={styles.form} action="">
                <div className={styles.item}>
                  <div className={styles.category}>Forgot Password</div>
                  <div className={styles.text}>
                  We'll email your instructions on how to reset it
                    
                  </div>
                  <div className={styles.fieldset}>
                    <TextInput
                      className={styles.field}
                      label="Email"
                      name="email"
                    //   value={email}
                      type="text"
                      onChange={(e) => {
                        emailFunction(e);
                      }}
                      placeholder="example@gmail.com"
                      required
                    />
                    {emailError && <div className={styles.alert}>{emailError}</div>}
                    
                {error && <div className={styles.alert}>{error}</div>}
                  </div>
                </div>
                
                <div className={styles.foot}>
                  
                  <button
                    className={cn("button", styles.button)}
                    onClick={() => createItem()}
                    type="button"
                  >
                    <span>Submit</span>
                  </button>
                </div>
                <Link to={"/"} >
                  <div className={styles.linkhome}>
                    <Icon
                      className={styles.icon}
                      name="arrow-prev"
                      fill="blue"
                    />{" "}
                   <span> Back to home{" "}</span>
                  </div>
                </Link>
                <div className={styles.item}>
                  {isLoading && (
                    <div className={styles.saving}>
                      <Loader className={styles.loader} />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}

export default ForgotPassword;
