import Owl from "../images/Group 44.png";
import Duck from "../images/Group 48.png";
import Sculpture from "../images/Group 46.png";
import Woman from "../images/woman.png";
import BeanLogo from "../images/beanlogo.png";
import Bean1 from "../images/bean1.png";
import Birdss from "../images/birds.png";
import Bottle from "../images/bottle.png";
import Benquine from "../images/benquine.png";
import Luna from "../images/penquin.png";

export const nfts = [
    {
        _id: "620f4fae6390f463a2dtgcfe4",
        urlname:"topnft",
        collectioName: "Bean",
        name: "@BEANZ Official",
        rarity: "EPIC",
        availability:
          "The Garbo is the SHORTIE that only SuperFans can acquire.  Just like Greta Garbo herself, this sleek and beautiful SHORTIE gives access to a select few to deep involvement in the exciting world of film and shorts. There are 222 Garbos available.",
        contractAddress: "0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
        nftContractAddress: "0x521EE5442d48f38e24CcbDF865879aF8A4B80aE9",
        description:
          "The Garbo SHORTIE honors the memory of Greta Garbo, that siren of the silent screen who blazed a trail through Hollywood that few have matched.  ",
        previewImage: Bean1,
        logo: BeanLogo,
        attachments: [
          {
            url: Owl,
            fileType: "IMAGE",
            _id: "620f4fae6390f463a2aaad00",
          },
          {
            fileType: "VIDEO",
            _id: "620f4fae6390f463a2aaad00",
          },
        ],
        active: true,
        showInMarketplace: true,
        saleType: {
          _id: "61e6710b1c622dcc5c563b7f",
          displayName: "SALE",
          type: "buyable",
          description: "Buyable assets",
        },
        assetType: {
          _id: "620219a15d59ebffa605ec9c",
          displayName: "ACCESSORIES",
          type: "accessories",
          description: "Accessories assets",
        },
        tokenPrice: 896.88,
        status: "LIVE",
        price: 0.1,
        supply: 2222,
        available: 2222,
        tieredPricing: false,
        chainName: "Polygon",
        chainId: 1,
        currency: "MATIC",
        assetName: "GOLD",
        tokenId: 1,
        category: "topNFTs",
        tags: ["SETVI"],
        openseaUrl: "https://testnets.opensea.io/collection/shortstv",
        explorerLink:
          "https://rinkeby.etherscan.io/address/0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
      },
      {
        _id: "620f4fae6390f463a2aaaghc4",
        urlname:"topnft",
        collectioName: "Pudgy Penguin",
        name: "Pudgy Penguin",
        rarity: "EPIC",
        availability:
          "The Garbo is the SHORTIE that only SuperFans can acquire.  Just like Greta Garbo herself, this sleek and beautiful SHORTIE gives access to a select few to deep involvement in the exciting world of film and shorts. There are 222 Garbos available.",
        contractAddress: "0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
        nftContractAddress: "0x521EE5442d48f38e24CcbDF865879aF8A4B80aE9",
        description:
          "The Garbo SHORTIE honors the memory of Greta Garbo, that siren of the silent screen who blazed a trail through Hollywood that few have matched.  ",
        previewImage: Luna,
        logo: BeanLogo,
        attachments: [
          {
            url: Duck,
            fileType: "IMAGE",
            _id: "620f4fae6390f463a2aaad00",
          },
          {
            fileType: "VIDEO",
            _id: "620f4fae6390f463a2aaad00",
          },
        ],
        active: true,
        showInMarketplace: true,
        saleType: {
          _id: "61e6710b1c622dcc5c563b7f",
          displayName: "SALE",
          type: "buyable",
          description: "Buyable assets",
        },
        assetType: {
          _id: "620219a15d59ebffa605ec9c",
          displayName: "ACCESSORIES",
          type: "accessories",
          description: "Accessories assets",
        },
        tokenPrice: 621.82,
        status: "LIVE",
        price: 0.1,
        supply: 2222,
        available: 2222,
        tieredPricing: false,
        chainName: "Polygon",
        chainId: 1,
        currency: "MATIC",
        assetName: "GOLD",
        tokenId: 1,
        category: "topNFTs",
        tags: ["AR Backgrounds", "Filters", "SETVI"],
        openseaUrl: "https://testnets.opensea.io/collection/shortstv",
        explorerLink:
          "https://rinkeby.etherscan.io/address/0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
      },
      {
        _id: "620f4fae6390f463a2aaacf90",
        urlname:"topnft",
        collectioName: "Owel illustrate",
        name: "@PROOF_XYZ",
        rarity: "EPIC",
        availability:
          "The Garbo is the SHORTIE that only SuperFans can acquire.  Just like Greta Garbo herself, this sleek and beautiful SHORTIE gives access to a select few to deep involvement in the exciting world of film and shorts. There are 222 Garbos available.",
        contractAddress: "0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
        nftContractAddress: "0x521EE5442d48f38e24CcbDF865879aF8A4B80aE9",
        description:
          "The Garbo SHORTIE honors the memory of Greta Garbo, that siren of the silent screen who blazed a trail through Hollywood that few have matched.  ",
        previewImage: Sculpture,
        logo: BeanLogo,
        attachments: [
          {
            url: Sculpture,
            fileType: "IMAGE",
            _id: "620f4fae6390f463a2aaad00",
          },
          {
            fileType: "VIDEO",
            _id: "620f4fae6390f463a2aaad00",
          },
        ],
        active: true,
        showInMarketplace: true,
        saleType: {
          _id: "61e6710b1c622dcc5c563b7f",
          displayName: "SALE",
          type: "buyable",
          description: "Buyable assets",
        },
        assetType: {
          _id: "620219a15d59ebffa605ec9c",
          displayName: "ACCESSORIES",
          type: "accessories",
          description: "Accessories assets",
        },
        tokenPrice: 388.29,
        status: "LIVE",
        price: 0.1,
        supply: 2222,
        available: 2222,
        tieredPricing: false,
        chainName: "Polygon",
        chainId: 1,
        currency: "MATIC",
        assetName: "GOLD",
        tokenId: 1,
        category: "topNFTs",
        tags: ["Face Swaps", "Filters", "SETVI"],
        openseaUrl: "https://testnets.opensea.io/collection/shortstv",
        explorerLink:
          "https://rinkeby.etherscan.io/address/0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
      },
      {
        _id: "620f4fae6390f463a2a0akje4",
        urlname:"topnft",
        collectioName: "Owel illustrate",
        name: "@PROOF_XYZ",
        rarity: "EPIC",
        availability:
          "The Garbo is the SHORTIE that only SuperFans can acquire.  Just like Greta Garbo herself, this sleek and beautiful SHORTIE gives access to a select few to deep involvement in the exciting world of film and shorts. There are 222 Garbos available.",
        contractAddress: "0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
        nftContractAddress: "0x521EE5442d48f38e24CcbDF865879aF8A4B80aE9",
        description:
          "The Garbo SHORTIE honors the memory of Greta Garbo, that siren of the silent screen who blazed a trail through Hollywood that few have matched.  ",
        previewImage: Woman,
        logo: BeanLogo,
        attachments: [
          {
            url: Woman,
            fileType: "IMAGE",
            _id: "620f4fae6390f463a2aaad00",
          },
          {
            fileType: "VIDEO",
            _id: "620f4fae6390f463a2aaad00",
          },
        ],
        active: true,
        showInMarketplace: true,
        saleType: {
          _id: "61e6710b1c622dcc5c563b7f",
          displayName: "SALE",
          type: "buyable",
          description: "Buyable assets",
        },
        assetType: {
          _id: "620219a15d59ebffa605ec9c",
          displayName: "ACCESSORIES",
          type: "accessories",
          description: "Accessories assets",
        },
        tokenPrice: 388.29,
        status: "LIVE",
        price: 0.1,
        supply: 2222,
        available: 2222,
        tieredPricing: false,
        chainName: "Polygon",
        chainId: 1,
        currency: "MATIC",
        assetName: "GOLD",
        tokenId: 1,
        category: "topNFTs",
        tags: ["AR Avatars", "AR Backgrounds", "SETVI"],
        openseaUrl: "https://testnets.opensea.io/collection/shortstv",
        explorerLink:
          "https://rinkeby.etherscan.io/address/0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
      },
      {
        _id: "620f4fae6390f463a2gh3cfe4",
        urlname:"topnft",
        collectioName: "SuperRare",
        name: "@Bird Art 4",
        rarity: "EPIC",
        availability:
          "The Garbo is the SHORTIE that only SuperFans can acquire.  Just like Greta Garbo herself, this sleek and beautiful SHORTIE gives access to a select few to deep involvement in the exciting world of film and shorts. There are 222 Garbos available.",
        contractAddress: "0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
        nftContractAddress: "0x521EE5442d48f38e24CcbDF865879aF8A4B80aE9",
        description:
          "The Garbo SHORTIE honors the memory of Greta Garbo, that siren of the silent screen who blazed a trail through Hollywood that few have matched.  ",
        previewImage: Birdss,
        logo: BeanLogo,
        attachments: [
          {
            url: Owl,
            fileType: "IMAGE",
            _id: "620f4fae6390f463a2aaad00",
          },
          {
            fileType: "VIDEO",
            _id: "620f4fae6390f463a2aaad00",
          },
        ],
        active: true,
        showInMarketplace: true,
        saleType: {
          _id: "61e6710b1c622dcc5c563b7f",
          displayName: "SALE",
          type: "buyable",
          description: "Buyable assets",
        },
        assetType: {
          _id: "620219a15d59ebffa605ec9c",
          displayName: "ACCESSORIES",
          type: "accessories",
          description: "Accessories assets",
        },
        tokenPrice: 818.18,
        status: "LIVE",
        price: 0.1,
        supply: 2222,
        available: 2222,
        tieredPricing: false,
        chainName: "Polygon",
        chainId: 1,
        currency: "MATIC",
        assetName: "GOLD",
        tokenId: 1,
        category: "topNFTs",
        tags: ["SETVI", "Filters"],
        openseaUrl: "https://testnets.opensea.io/collection/shortstv",
        explorerLink:
          "https://rinkeby.etherscan.io/address/0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
      },
      {
        _id: "620f4fae6390f463a2aaacfg4",
        urlname:"topnft",
        collectioName: "Owel illustrate",
        name: "@PROOF_XYZ",
        rarity: "EPIC",
        availability:
          "The Garbo is the SHORTIE that only SuperFans can acquire.  Just like Greta Garbo herself, this sleek and beautiful SHORTIE gives access to a select few to deep involvement in the exciting world of film and shorts. There are 222 Garbos available.",
        contractAddress: "0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
        nftContractAddress: "0x521EE5442d48f38e24CcbDF865879aF8A4B80aE9",
        description:
          "The Garbo SHORTIE honors the memory of Greta Garbo, that siren of the silent screen who blazed a trail through Hollywood that few have matched.  ",
        previewImage: Duck,
        logo: BeanLogo,
        attachments: [
          {
            url: Duck,
            fileType: "IMAGE",
            _id: "620f4fae6390f463a2aaad00",
          },
          {
            fileType: "VIDEO",
            _id: "620f4fae6390f463a2aaad00",
          },
        ],
        active: true,
        showInMarketplace: true,
        saleType: {
          _id: "61e6710b1c622dcc5c563b7f",
          displayName: "SALE",
          type: "buyable",
          description: "Buyable assets",
        },
        assetType: {
          _id: "620219a15d59ebffa605ec9c",
          displayName: "ACCESSORIES",
          type: "accessories",
          description: "Accessories assets",
        },
        tokenPrice: 388.29,
        status: "LIVE",
        price: 0.1,
        supply: 2222,
        available: 2222,
        tieredPricing: false,
        chainName: "Polygon",
        chainId: 1,
        currency: "MATIC",
        assetName: "GOLD",
        tokenId: 1,
        category: "topNFTs",
        tags: ["AR Backgrounds", "Filters", "SETVI"],
        openseaUrl: "https://testnets.opensea.io/collection/shortstv",
        explorerLink:
          "https://rinkeby.etherscan.io/address/0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
      },
      {
        _id: "620f4fae6390f463a2aaaf780",
        urlname:"topnft",
        collectioName: "Pudgy Penguins",
        name: "@Pudgy Penguin",
        rarity: "EPIC",
        availability:
          "The Garbo is the SHORTIE that only SuperFans can acquire.  Just like Greta Garbo herself, this sleek and beautiful SHORTIE gives access to a select few to deep involvement in the exciting world of film and shorts. There are 222 Garbos available.",
        contractAddress: "0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
        nftContractAddress: "0x521EE5442d48f38e24CcbDF865879aF8A4B80aE9",
        description:
          "The Garbo SHORTIE honors the memory of Greta Garbo, that siren of the silent screen who blazed a trail through Hollywood that few have matched.  ",
        previewImage: Benquine,
        logo: BeanLogo,
        attachments: [
          {
            url: Sculpture,
            fileType: "IMAGE",
            _id: "620f4fae6390f463a2aaad00",
          },
          {
            fileType: "VIDEO",
            _id: "620f4fae6390f463a2aaad00",
          },
        ],
        active: true,
        showInMarketplace: true,
        saleType: {
          _id: "61e6710b1c622dcc5c563b7f",
          displayName: "SALE",
          type: "buyable",
          description: "Buyable assets",
        },
        assetType: {
          _id: "620219a15d59ebffa605ec9c",
          displayName: "ACCESSORIES",
          type: "accessories",
          description: "Accessories assets",
        },
        tokenPrice: 3240.01,
        status: "LIVE",
        price: 0.1,
        supply: 2222,
        available: 2222,
        tieredPricing: false,
        chainName: "Polygon",
        chainId: 1,
        currency: "MATIC",
        assetName: "GOLD",
        tokenId: 1,
        category: "topNFTs",
        tags: ["Face Swaps", "Filters", "SETVI"],
        openseaUrl: "https://testnets.opensea.io/collection/shortstv",
        explorerLink:
          "https://rinkeby.etherscan.io/address/0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
      },
      {
        _id: "620f4fae6390f463a2a0akje4",
        urlname:"topnft",
        collectioName: "Owel illustrate",
        name: "@PROOF_XYZ",
        rarity: "EPIC",
        availability:
          "The Garbo is the SHORTIE that only SuperFans can acquire.  Just like Greta Garbo herself, this sleek and beautiful SHORTIE gives access to a select few to deep involvement in the exciting world of film and shorts. There are 222 Garbos available.",
        contractAddress: "0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
        nftContractAddress: "0x521EE5442d48f38e24CcbDF865879aF8A4B80aE9",
        description:
          "The Garbo SHORTIE honors the memory of Greta Garbo, that siren of the silent screen who blazed a trail through Hollywood that few have matched.  ",
        previewImage: Woman,
        logo: BeanLogo,
        attachments: [
          {
            url: Woman,
            fileType: "IMAGE",
            _id: "620f4fae6390f463a2aaad00",
          },
          {
            fileType: "VIDEO",
            _id: "620f4fae6390f463a2aaad00",
          },
        ],
        active: true,
        showInMarketplace: true,
        saleType: {
          _id: "61e6710b1c622dcc5c563b7f",
          displayName: "SALE",
          type: "buyable",
          description: "Buyable assets",
        },
        assetType: {
          _id: "620219a15d59ebffa605ec9c",
          displayName: "ACCESSORIES",
          type: "accessories",
          description: "Accessories assets",
        },
        tokenPrice: 388.29,
        status: "LIVE",
        price: 0.1,
        supply: 2222,
        available: 2222,
        tieredPricing: false,
        chainName: "Polygon",
        chainId: 1,
        currency: "MATIC",
        assetName: "GOLD",
        tokenId: 1,
        category: "topNFTs",
        tags: ["AR Avatars", "AR Backgrounds", ],
        openseaUrl: "https://testnets.opensea.io/collection/shortstv",
        explorerLink:
          "https://rinkeby.etherscan.io/address/0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
      },
      {
        _id: "620f4fae6390f463a2aaacfe4",
        urlname:"topnft",
        collectioName: "Owel illustrate",
        name: "@PROOF_XYZ",
        rarity: "EPIC",
        availability:
          "The Garbo is the SHORTIE that only SuperFans can acquire.  Just like Greta Garbo herself, this sleek and beautiful SHORTIE gives access to a select few to deep involvement in the exciting world of film and shorts. There are 222 Garbos available.",
        contractAddress: "0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
        nftContractAddress: "0x521EE5442d48f38e24CcbDF865879aF8A4B80aE9",
        description:
          "The Garbo SHORTIE honors the memory of Greta Garbo, that siren of the silent screen who blazed a trail through Hollywood that few have matched.  ",
        previewImage: Owl,
        logo: BeanLogo,
        attachments: [
          {
            url: Owl,
            fileType: "IMAGE",
            _id: "620f4fae6390f463a2aaad00",
          },
          {
            fileType: "VIDEO",
            _id: "620f4fae6390f463a2aaad00",
          },
        ],
        active: true,
        showInMarketplace: true,
        saleType: {
          _id: "61e6710b1c622dcc5c563b7f",
          displayName: "SALE",
          type: "buyable",
          description: "Buyable assets",
        },
        assetType: {
          _id: "620219a15d59ebffa605ec9c",
          displayName: "ACCESSORIES",
          type: "accessories",
          description: "Accessories assets",
        },
        tokenPrice: 388.29,
        status: "LIVE",
        price: 0.1,
        supply: 2222,
        available: 2222,
        tieredPricing: false,
        chainName: "Polygon",
        chainId: 1,
        currency: "MATIC",
        assetName: "GOLD",
        tokenId: 1,
        category: "topNFTs",
        tags: ["SETVI", "Filters"],
        openseaUrl: "https://testnets.opensea.io/collection/shortstv",
        explorerLink:
          "https://rinkeby.etherscan.io/address/0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
      },
      {
        _id: "620f4fae6390f463a2abacfg4",
        urlname:"topnft",
        collectioName: "SuperRare",
        name: "@KRAKEN IN THE BOTTLE",
        rarity: "EPIC",
        availability:
          "The Garbo is the SHORTIE that only SuperFans can acquire.  Just like Greta Garbo herself, this sleek and beautiful SHORTIE gives access to a select few to deep involvement in the exciting world of film and shorts. There are 222 Garbos available.",
        contractAddress: "0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
        nftContractAddress: "0x521EE5442d48f38e24CcbDF865879aF8A4B80aE9",
        description:
          "The Garbo SHORTIE honors the memory of Greta Garbo, that siren of the silent screen who blazed a trail through Hollywood that few have matched.  ",
        previewImage: Bottle,
        logo: BeanLogo,
        attachments: [
          {
            url: Duck,
            fileType: "IMAGE",
            _id: "620f4fae6390f463a2aaad00",
          },
          {
            fileType: "VIDEO",
            _id: "620f4fae6390f463a2aaad00",
          },
        ],
        active: true,
        showInMarketplace: true,
        saleType: {
          _id: "61e6710b1c622dcc5c563b7f",
          displayName: "SALE",
          type: "buyable",
          description: "Buyable assets",
        },
        assetType: {
          _id: "620219a15d59ebffa605ec9c",
          displayName: "ACCESSORIES",
          type: "accessories",
          description: "Accessories assets",
        },
        tokenPrice: 274.91,
        status: "LIVE",
        price: 0.1,
        supply: 2222,
        available: 2222,
        tieredPricing: false,
        chainName: "Polygon",
        chainId: 1,
        currency: "MATIC",
        assetName: "GOLD",
        tokenId: 1,
        category: "topNFTs",
        tags: ["AR Backgrounds", "Filters", "SETVI"],
        openseaUrl: "https://testnets.opensea.io/collection/shortstv",
        explorerLink:
          "https://rinkeby.etherscan.io/address/0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
      },
      {
        _id: "620f4fae6390f463a2aaacf90",
        urlname:"topnft",
        collectioName: "Owel illustrate",
        name: "@PROOF_XYZ",
        rarity: "EPIC",
        availability:
          "The Garbo is the SHORTIE that only SuperFans can acquire.  Just like Greta Garbo herself, this sleek and beautiful SHORTIE gives access to a select few to deep involvement in the exciting world of film and shorts. There are 222 Garbos available.",
        contractAddress: "0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
        nftContractAddress: "0x521EE5442d48f38e24CcbDF865879aF8A4B80aE9",
        description:
          "The Garbo SHORTIE honors the memory of Greta Garbo, that siren of the silent screen who blazed a trail through Hollywood that few have matched.  ",
        previewImage: Sculpture,
        logo: BeanLogo,
        attachments: [
          {
            url: Sculpture,
            fileType: "IMAGE",
            _id: "620f4fae6390f463a2aaad00",
          },
          {
            fileType: "VIDEO",
            _id: "620f4fae6390f463a2aaad00",
          },
        ],
        active: true,
        showInMarketplace: true,
        saleType: {
          _id: "61e6710b1c622dcc5c563b7f",
          displayName: "SALE",
          type: "buyable",
          description: "Buyable assets",
        },
        assetType: {
          _id: "620219a15d59ebffa605ec9c",
          displayName: "ACCESSORIES",
          type: "accessories",
          description: "Accessories assets",
        },
        tokenPrice: 388.29,
        status: "LIVE",
        price: 0.1,
        supply: 2222,
        available: 2222,
        tieredPricing: false,
        chainName: "Polygon",
        chainId: 1,
        currency: "MATIC",
        assetName: "GOLD",
        tokenId: 1,
        category: "topNFTs",
        tags: ["Face Swaps", "Filters", "SETVI"],
        openseaUrl: "https://testnets.opensea.io/collection/shortstv",
        explorerLink:
          "https://rinkeby.etherscan.io/address/0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
      },
      {
        _id: "620f4fae6390f463a2a0akje4",
        urlname:"topnft",
        collectioName: "Owel illustrate",
        name: "@PROOF_XYZ",
        rarity: "EPIC",
        availability:
          "The Garbo is the SHORTIE that only SuperFans can acquire.  Just like Greta Garbo herself, this sleek and beautiful SHORTIE gives access to a select few to deep involvement in the exciting world of film and shorts. There are 222 Garbos available.",
        contractAddress: "0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
        nftContractAddress: "0x521EE5442d48f38e24CcbDF865879aF8A4B80aE9",
        description:
          "The Garbo SHORTIE honors the memory of Greta Garbo, that siren of the silent screen who blazed a trail through Hollywood that few have matched.  ",
        previewImage: Woman,
        logo: BeanLogo,
        attachments: [
          {
            url: Woman,
            fileType: "IMAGE",
            _id: "620f4fae6390f463a2aaad00",
          },
          {
            fileType: "VIDEO",
            _id: "620f4fae6390f463a2aaad00",
          },
        ],
        active: true,
        showInMarketplace: true,
        saleType: {
          _id: "61e6710b1c622dcc5c563b7f",
          displayName: "SALE",
          type: "buyable",
          description: "Buyable assets",
        },
        assetType: {
          _id: "620219a15d59ebffa605ec9c",
          displayName: "ACCESSORIES",
          type: "accessories",
          description: "Accessories assets",
        },
        tokenPrice: 388.29,
        status: "LIVE",
        price: 0.1,
        supply: 2222,
        available: 2222,
        tieredPricing: false,
        chainName: "Polygon",
        chainId: 1,
        currency: "MATIC",
        assetName: "GOLD",
        tokenId: 1,
        category: "topNFTs",
        tags: ["AR Avatars", "AR Backgrounds", ],
        openseaUrl: "https://testnets.opensea.io/collection/shortstv",
        explorerLink:
          "https://rinkeby.etherscan.io/address/0x54BfD424207B005EDdaC6e655D435Eb67CC968B8",
      },
]