import { combineReducers } from 'redux'

import PostsReducer from './Posts/PostsReducer'
import UserReducer from './User/UserReducer'
import LocationReducer from './Location/LocationReducer'


const rootReducer = combineReducers ({
    Posts:PostsReducer,
    User:UserReducer,
    Modal:LocationReducer

})

export default rootReducer
