import React, {useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
// import { clearAllBodyScrollLocks } from "body-scroll-lock";
// import { lock, unlock, clearBodyLocks } from 'tua-body-scroll-lock';
import styles from "./Page.module.sass";
import Header from "../Header";
import Footer from "../Footer";
// import bgnew from "../../components/";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  getErrorMessage,
  injected,
  useEagerConnect,
  useInactiveListener,
} from "../../utils/connections.ts";
import { useSnackbar } from "notistack";
import { Alert } from 'antd';

const Page = ({ children,history }) => {
  const header = React.useRef();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [showWarningBanner, setShowWarningBanner] = useState(false);
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [showButton, setShowButton] = useState(false);
  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager);

  useEffect(() => {
    window.scrollTo(0, 0);
    // clearBodyLocks();
  }, [pathname]);

  useEffect(() => {
    if (error) {
      if (error instanceof UnsupportedChainIdError) {
        setShowWarningBanner(true);
      enqueueSnackbar("Your wallet is not connected to Polygon Mainnet. Please change your network.", { variant: "error" });
      }
      else if (error) {
        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      }
    }
  }, [error]);
  useEffect(() => {
    if (active) {
      setShowWarningBanner(false);
    }
  }, [active]);
  
  const onCloseNotification = () => setShowWarningBanner(false);
  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     if (window.pageYOffset > 280) {
  //       setShowButton(true);
  //     } else {
  //       setShowButton(false);
  //     }
  //   });
  // }, []);

  // function smoothScroll(target) {
  //   const defaultValue = { width: 0 };
  //   const { top } = target.getBoundingClientRect();

  //   window.scrollTo({
  //     top: top + window.pageYOffset,
  //     behavior: "smooth",
  //   });
  // }

  return (
    <div className={styles.page} ref={header}>
      <Header 
        history={history}
        active={active}
        account={account}
        error={error}
        connect={() => activate(injected)}
        logout={() => deactivate()}
        // ref={header} 
        />
        {/* {showWarningBanner &&
      <Alert
      message="Your wallet is not connected to Polygon test network. Please change your network."
      type="warning"
      closable
      onClose={onCloseNotification}
      style={{position:"absolute",top:"10%",zIndex:"10", width:"100%" , backgroundColor: "#ee9200","border": "none", fontWeight:700,textAlign:"center"}}
    />} */}
      <div className={styles.inner}
      >{children}</div>
      <Footer history={history}/>
    </div>
  );
};

export default withRouter(Page);
