import React, { useState } from 'react';
import styles from './Mobile.module.sass';
import Modal from '../Modal';
import cn from 'classnames';
import { Col, Row } from 'antd';
import TextInput1 from '../TextInput1';
import TextArea from '../TextArea';
import {
  fetchAnalyticsData,
  fetchBigQueryData,
} from '../../services/ApiServices';

const MobileData = () => {
  const [activeButton, setActiveButton] = useState(null);
  const [isGetDataButtonClicked, setIsGetDataButtonClicked] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isGetBigQueryDataButtonClicked, setIsGetBigQueryDataButtonClicked] =
    useState(false);
  const [isBigQueryActive, setBigQueryActive] = useState(false);
  const [isPinnedFeedsModalVisible, setIsPinnedFeedsModalVisible] =
    useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    from: '',
    to: '',
  });
  const [bigQuerySelectedDateRange, setBigQuerySelectedDateRange] = useState({
    from: '',
    to: '',
  });
  const [errormsg, setErrormsg] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [bigQueryErrormsg, setBigQueryErrormsg] = useState(null);
  const [bigQuerySuccessMessage, setBigQuerySuccessMessage] = useState(null);
  const [deletePinnedItemModalVisible, setDeletePinnedItemModalVisible] =
    useState(false);
  const [deletePinnedItemInput, setDeletePinnedItemInput] = useState('');

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    setIsActive(buttonName === 'Analytics Data');
    setBigQueryActive(buttonName === 'BigQuery Data');
  };

  const handleDeletePinnedItem = () => {
    setDeletePinnedItemModalVisible(true);
  };

  const handleDeletePinnedItemSubmit = () => {
    console.log('Deleting pinned item with input:', deletePinnedItemInput);

    // Close the modal after submission
    setDeletePinnedItemModalVisible(false);
  };

  const handleCreateNewPinnedItem = () => {
    setIsPinnedFeedsModalVisible(true);
  };

  const handleAllDeletePinnedItems = () => {
    setIsPinnedFeedsModalVisible(true);
  };

  const GetAnalyticsData = async () => {
    if (!selectedDateRange.from || !selectedDateRange.to) {
      setErrormsg("Please provide both 'From Date' and 'To Date'.");
    } else {
      setErrormsg(null); // Clear any previous error
      setSuccessMessage(
        'Your request is currently being processed. Once it is completed, you will receive an email.'
      );
      setIsGetDataButtonClicked(true);
      let body = {
        emailId: localStorage.getItem('email'),
        startTime: `${selectedDateRange.from}T00:00:00Z`,
        endTime: `${selectedDateRange.to}T23:59:59Z`,
      };
      const result = await fetchAnalyticsData(body);
      // console.log('mobile data response is ', result);
      if (result.success) {
        setErrormsg(null);
        setSuccessMessage(
          `Analytics data has been successfully delivered to your email`
        );
        setIsGetDataButtonClicked(false);
      } else {
        setSuccessMessage(null);
        setErrormsg('Something went wrong');
        setIsGetDataButtonClicked(false);
      }
    }
  };

  const GetBigQueryData = async () => {
    if (!bigQuerySelectedDateRange.from || !bigQuerySelectedDateRange.to) {
      setBigQueryErrormsg("Please provide both 'From Date' and 'To Date'.");
    } else {
      setBigQueryErrormsg(null); // Clear any previous error
      setBigQuerySuccessMessage(
        'Your request is currently being processed. Once it is completed, you will receive an email.'
      );
      setIsGetBigQueryDataButtonClicked(true);
      let body = {
        emailId: localStorage.getItem('email'),
        startTime: `${bigQuerySelectedDateRange.from}T00:00:00Z`,
        endTime: `${bigQuerySelectedDateRange.to}T23:59:59Z`,
      };
      const result = await fetchBigQueryData(body);
      // console.log('mobile data response is ', result);
      if (result.success) {
        setBigQueryErrormsg(null);
        if (result?.bigQueryData === 'Email sent successfully') {
          setBigQuerySuccessMessage(
            `bigQuery data has been successfully delivered to your email`
          );
        } else if (
          result?.bigQueryData === 'No data found for the specified date range'
        ) {
          setBigQuerySuccessMessage(
            'No data found for the specified date range'
          );
        }
        setIsGetBigQueryDataButtonClicked(false);
      } else {
        setBigQuerySuccessMessage(null);
        setBigQueryErrormsg('Something went wrong');
        setIsGetBigQueryDataButtonClicked(false);
      }
    }
  };

  const buttons = ['Pinned Feeds', 'Analytics Data', 'BigQuery Data']; // 'Post', 'Poll', 'Rate',

  const handleToDateChange = (e) => {
    const selectedToDate = e.target.value;
    const today = new Date().toISOString().split('T')[0]; // Get today's date in the format 'YYYY-MM-DD'

    if (selectedToDate >= today || selectedToDate <= selectedDateRange.from) {
      // If the selected date is today or earlier, prevent the change
      setSelectedDateRange({
        ...selectedDateRange,
        to: '',
      });
    } else {
      setSelectedDateRange({
        ...selectedDateRange,
        to: selectedToDate,
      });
    }
  };

  const handleBigQueryToDateChange = (e) => {
    const selectedToDate = e.target.value;
    const today = new Date().toISOString().split('T')[0]; // Get today's date in the format 'YYYY-MM-DD'

    if (
      bigQuerySelectedDateRange.to >= today ||
      selectedToDate <= bigQuerySelectedDateRange.from
    ) {
      // If the selected date is today or earlier, prevent the change
      setBigQuerySelectedDateRange({
        ...bigQuerySelectedDateRange,
        to: '',
      });
    } else {
      setBigQuerySelectedDateRange({
        ...bigQuerySelectedDateRange,
        to: selectedToDate,
      });
    }
  };

  return (
    <>
      <div className={styles.buttonGrid}>
        {buttons.map((buttonName) => (
          <div key={buttonName}>
            <button
              className={`${styles.button} ${
                activeButton === buttonName ? styles.activeButton : ''
              }`}
              onClick={() => handleButtonClick(buttonName)}
            >
              {buttonName}
            </button>

            {/* Additional buttons for "Post" */}
            {activeButton === 'Post' && buttonName === 'Post' && (
              <div className={styles.additionalButtons}>
                <button>Edit Post Content</button>
                <button>Edit Post Image</button>
                <button>Delete Post</button>
              </div>
            )}

            {activeButton === 'Pinned Feeds' &&
              buttonName === 'Pinned Feeds' && (
                <div className={styles.additionalButtons}>
                  <button
                    className={styles.deletePinnedItemButton}
                    onClick={handleDeletePinnedItem}
                  >
                    Delete Pinned Item
                  </button>
                  <button onClick={handleCreateNewPinnedItem}>
                    Create New Pinned Item
                  </button>
                  <button onClick={handleAllDeletePinnedItems}>
                    Delete All Pinned Items
                  </button>
                </div>
              )}

            <Modal
              visible={isActive}
              onClose={() => setIsActive(false)}
              outerClassName={styles.deleveryModal}
            >
              {activeButton === 'Analytics Data' && (
                <div className={`${styles.analyticsData} modalContent`}>
                  <h3>Analytics Data</h3>
                  <label>
                    <b>From Date</b>
                  </label>
                  <input
                    className={styles.leftColumn}
                    type="date"
                    value={selectedDateRange.from}
                    onChange={(e) =>
                      setSelectedDateRange({
                        ...selectedDateRange,
                        from: e.target.value,
                      })
                    }
                  />
                  <label>
                    <b>To Date</b>
                  </label>
                  <input
                    className={styles.leftColumn}
                    type="date"
                    value={selectedDateRange.to}
                    onChange={handleToDateChange}
                  />
                  {isGetDataButtonClicked ? (
                    ''
                  ) : (
                    <button onClick={GetAnalyticsData}>
                      <b>Get Data</b>
                    </button>
                  )}
                  {errormsg && <div className={styles.alert}>{errormsg}</div>}
                  {successMessage && (
                    <div className={styles.success}>{successMessage}</div>
                  )}
                </div>
              )}
            </Modal>
            <Modal
              visible={deletePinnedItemModalVisible}
              onClose={() => setDeletePinnedItemModalVisible(false)}
              outerClassName={styles.deletePinnedItemModal}
            >
              {activeButton === 'Pinned Feeds' && (
                <div className={`${styles.deletePinnedItem} modalContent`}>
                  <h3>Delete Pinned Item</h3>
                  <label>
                    <b>Share URL or Feed ID:</b>
                  </label>
                  <input
                    type="text"
                    value={deletePinnedItemInput}
                    onChange={(e) => setDeletePinnedItemInput(e.target.value)}
                  />
                  <button onClick={handleDeletePinnedItemSubmit}>
                    <b>Submit</b>
                  </button>
                </div>
              )}
            </Modal>

            <Modal
              visible={isBigQueryActive}
              onClose={() => setBigQueryActive(false)}
              outerClassName={styles.deleveryModal}
            >
              {activeButton === 'BigQuery Data' && (
                <div className={`${styles.analyticsData} modalContent`}>
                  <h3>BigQuery Data</h3>
                  <label>
                    <b>From Date</b>
                  </label>
                  <input
                    className={styles.leftColumn}
                    type="date"
                    value={bigQuerySelectedDateRange.from}
                    onChange={(e) =>
                      setBigQuerySelectedDateRange({
                        ...bigQuerySelectedDateRange,
                        from: e.target.value,
                      })
                    }
                  />
                  <label>
                    <b>To Date</b>
                  </label>
                  <input
                    className={styles.leftColumn}
                    type="date"
                    value={bigQuerySelectedDateRange.to}
                    onChange={handleBigQueryToDateChange}
                  />
                  {isGetBigQueryDataButtonClicked ? (
                    ''
                  ) : (
                    <button onClick={GetBigQueryData}>
                      <b>Get Data</b>
                    </button>
                  )}
                  {bigQueryErrormsg && (
                    <div className={styles.alert}>{bigQueryErrormsg}</div>
                  )}
                  {bigQuerySuccessMessage && (
                    <div className={styles.success}>
                      {bigQuerySuccessMessage}
                    </div>
                  )}
                </div>
              )}
            </Modal>
          </div>
        ))}
      </div>
    </>
  );
};
export default MobileData;
