import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Card.module.sass";
import { Link } from "react-router-dom";

const royaltiesOptions = ["flower", "fruit", "tree", "pet","work","Art"];

const items = [
  {
    title: "Create collection",
    color: "#4BC9F0",
  },
  {
    title: "Crypto Legend - Professor",
    color: "#45B26B",
  },
  {
    title: "Crypto Legend - Professor",
    color: "#EF466F",
  },
  {
    title: "Legend Photography",
    color: "#9757D7",
  },
];
const options = [
  { label: "Art", value: "art" },
  { label: "Work", value: "work",},
  { label: "Games", value: "games",},
  { label: "Photography", value: "photography",},
  { label: "Music", value: "music",},
  { label: "Video", value: "video",},
];
const Card = ({ children,history }) => {
 
  return (
      <>
      <Link  to ={'/'}>
      <img className={styles.logo} src="https://static.wixstatic.com/media/8c2a7e_2c21add094254ba7ae69ef8cc92afd66~mv2.png/v1/fill/w_84,h_83,al_c,q_85,usm_0.66_1.00_0.01/OKTOCHAIN%20RED.webp" />
      </Link>
      <div  className={styles.body}>
    <div className={styles.card}>
          <div className={styles.inner}>{children}</div>
    </div>
    </div>
    </>
  );
};

export default Card;
