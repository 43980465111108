import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./nft.module.sass";
import Image from "../../components/Image";
import Form from "../../components/Form";
import { Table } from "antd";
import {
  GetFollowers,
  GetFollowings,
  GetUserAssets,
  GetBuyerUser,
  GetUser,
} from "../../services/ApiServices";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { getUserPosts } from "../../redux";
const columns = [
  {
    title: "No",
    dataIndex: "no",
    width: "1%",
    render: (text, row, index) => {
      return <p>{index + 1}</p>;
    },
  },
  {
    title: "Post",
    dataIndex: "attachments",
    width: "10%",
    render: (text, row, index) => {
      if (text[0].fileType == "image") {
        return <img src={text[0].url} height="50px" />;
      }
    },
  },
  {
    title: "Name",
    dataIndex: "title",
    width: "15%",
  },

  {
    title: "Category",
    dataIndex: "tags",
    width: "20%",
    render: (text, row, index) => {
      return text.map((data, index) => {
        if (index == text.length - 1) return <span>{data} </span>;
        else return <span>{data}, </span>;
      });
    },
  },
  {
    title: "Total Copies",
    dataIndex: "totalSupply",
    width: "10%",
  },
  {
    title: "Available Copies",
    dataIndex: "stockAvailable",
    width: "10%",
  },
  {
    title: "Create Date",
    dataIndex: "createdAt",
    width: "10%",
    render: (text, row, index) => {
      return <p>{moment(text).format("DD-MM-YYYY")}</p>;
    },
  },
  {
    title: "Status",
    dataIndex: "active",
    width: "10%",
  },
  {
    title: "Action",
    dataIndex: "id",
    width: "10%",
    render: (text, row, index) => {
      return <Link to={"/Seller-item/" + text}>View details</Link>;
    },
  },
];

const Nft = ({ history }) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [following, setFollowing] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [user, setUser] = useState({});
  const [userLoading, setUserLoading] = useState(false);
  const [search, setSearch] = useState("");

  const handleSubmit = (e) => {
    alert();
  };
  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }
  useEffect(() => {
    setUser(state.User.user);
  }, [state.User.user]);

  useEffect(() => {
    setAssets(state.Posts.userPost);
    console.log(state.Posts);
  }, [state.Posts.userPost]);


  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.title}>
          NFT Listing
          <div className={styles.createButton}>
            <button
              className={cn("button button-small", styles.button)}
              onClick={() => history.push("/Seller-Upload")}
            >
              <span>Create Nft</span>
              <PlusOutlined />
            </button>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={assets}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default Nft;
