import cn from "classnames";
import styles from "./SideNav.module.sass";
// Be sure to include styles at some point, probably during your bootstraping
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { useDispatch, useSelector } from "react-redux";
import './SideNav.css'
import React from "react";
import { CLEARGETPOSTS } from "../../redux/Posts/PostsTypes";


const Sidenav = ({ children, history }) => {
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
  return (
    <>
      
      </>
 
 );
};
export default Sidenav;
