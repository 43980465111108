import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Card.module.sass";
import Icon from "../Icon";
import { GetUSD } from "../../services/ApiServices";
import Bid from "../../mocks/bids";
const Card = ({ className, item }) => {
  const [visible, setVisible] = useState(false);
  const [value,setValue] = useState("")
  const getUSD = async () => {
    let result;
    try {
      result = await GetUSD();
    } catch (e) {
      console.log(e);
    
    }

    try {
      if (result) {
       setValue(result.USD) 
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(()=>{
    getUSD()
  },[])

  return (
    <div className={cn(styles.card, className)}>
      <div className={styles.preview}>
        {item.attachments[0].fileType == "image" ? (
          <img
            srcSet={`${item.attachments[0].url} 2x`}
            src={item.attachments[0].url}
            alt="Card"
          />
        ) : (
          <video
            autoPlay
            muted
            loop
            src={item.attachments[0].url}
            type="video/mp4"
          />
        )}

        <div className={styles.control}>
          <div
            className={cn(
              { "status-green": item.category === "green" },
              styles.category
            )}
          >
            {item.categoryText}
          </div>
          <button
            className={cn(styles.favorite, { [styles.active]: visible })}
            onClick={() => setVisible(!visible)}
          >
            <Icon name="heart" size="20" />
          </button>
          {/* <button className={cn("button-small", styles.button)}>
            <span>Place a bid</span>
            <Icon name="scatter-up" size="16" />
          </button> */}
        </div>
      </div>
      <Link className={styles.link} to={"/Seller-Asset/" + item.id}>
        <div className={styles.body}>
          <div className={styles.line}>
            <div className={styles.title}>{item.title}</div>
            <div className={styles.price}>{item.tokenPrice} Eth</div>
          </div>
          <div className={styles.line}>
            <div className={styles.users}>
                <Link to={'/profile/'+item.userId.id}>
              <div className={styles.avatar}>
                <img src={item.userId.imageUrl} alt="Avatar" />
                <div>{item.userId.userName}</div>
              </div>
              </Link>
            </div>
            <div className={styles.counter}>${Bid=="OKTO"?item.tokenPrice: (value*item.tokenPrice).toFixed(2)}</div>
          </div>
        </div>
        {/* <div className={styles.foot}>
          <div className={styles.status}>
            <Icon name="candlesticks-up" size="20" />
            Highest bid <span>{item.earnedCoins}</span>
          </div>
          <div
            className={styles.bid}
            // dangerouslySetInnerHTML={{ __html: item.bid }}
          />
        </div> */}
      </Link>
    </div>
  );
};

export default Card;
