import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
// import { clearAllBodyScrollLocks } from "body-scroll-lock";
// import { lock, unlock, clearBodyLocks } from 'tua-body-scroll-lock';

import styles from "./Page.module.sass";
import HeaderContent from "../SellerHeader";
// import Footer from "../Footer";
import User from "./User";
import { Layout, Menu } from "antd";
import {
  HomeFilled,
  AppstoreFilled,
  SettingFilled,
  PictureFilled,
  FundFilled,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../redux";
import firebase from "../../services/firebaseServices";

const { Header, Content, Footer, Sider } = Layout;

const Page = ({ children, history }) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [user, setUser] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    // clearBodyLocks();
  }, [pathname]);

  useEffect(() => {
    console.log("Checking user....",state.User.user);
    setUser(state.User.user);
  }, [state.User.user]);

  const userDetails = async (uid) => {
    dispatch(getUser(uid));
  };

  // const GetUser = () => {
  //   firebase.auth().onAuthStateChanged(function (user) {
  //     if (user) {
  //       userDetails(user.uid);
  //     } else {
  //       setUser();
  //     }
  //   });
  // };
  // useEffect(async () => {
  //   GetUser();
  // }, []);

  return (
    <Layout style={{ overflow: "hidden" }}>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          zIndex: 5,
          borderRight: "1px solid #efefef",
        }}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="logo">
          <img
            src="https://static.wixstatic.com/media/8c2a7e_2c21add094254ba7ae69ef8cc92afd66~mv2.png/v1/fill/w_84,h_83,al_c,q_85,usm_0.66_1.00_0.01/OKTOCHAIN%20RED.webp"
            onClick={() => history.push("/")}
          />
          <b>{user.userName}</b>
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item
            key="1"
            icon={<HomeFilled />}
            onClick={() => {
              history.push("/Seller-Home");
            }}
          >
            Home
          </Menu.Item>
          <Menu.Item
            key="2"
            icon={<FundFilled />}
            onClick={() => {
              history.push("/Seller-Nft");
            }}
          >
            NFT
          </Menu.Item>
          <Menu.Item key="3" icon={<PictureFilled />}>
            Collection
          </Menu.Item>

          <Menu.Item
            key="4"
            onClick={() => {
              history.push("/Seller-order");
            }}
            icon={
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.8333 4.49967H12.1667C12.1667 2.19967 10.3 0.333008 8 0.333008C5.7 0.333008 3.83333 2.19967 3.83333 4.49967H2.16667C1.25 4.49967 0.508333 5.24968 0.508333 6.16634L0.5 16.1663C0.5 17.083 1.25 17.833 2.16667 17.833H13.8333C14.75 17.833 15.5 17.083 15.5 16.1663V6.16634C15.5 5.24968 14.75 4.49967 13.8333 4.49967ZM8 1.99967C9.38333 1.99967 10.5 3.11634 10.5 4.49967H5.5C5.5 3.11634 6.61667 1.99967 8 1.99967ZM8 10.333C5.7 10.333 3.83333 8.46634 3.83333 6.16634H5.5C5.5 7.54968 6.61667 8.66634 8 8.66634C9.38333 8.66634 10.5 7.54968 10.5 6.16634H12.1667C12.1667 8.46634 10.3 10.333 8 10.333Z"
                  fill="#777E90"
                ></path>
              </svg>
            }
          >
            Sell Order
          </Menu.Item>
          <Menu.Item key="5" icon={<SettingFilled />}>
            Settings
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: 200 }}>
        <Header
          className="site-layout-sub-header-background"
          style={{
            padding: "10px 303px",
            position: "fixed",
            width: "100%",
            zIndex: 5,
            borderBottom: "1px solid #efefef",
          }}
        >
          <User
            className="user"
            userDetail={user}
            // refresh={GetUser}
            history={history}
          />
        </Header>
        <Content style={{ margin: "24px 0px 0", overflow: "initial" }}>
          {/* <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          content
        </div> */}
          <div className="inner">{children}</div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          © 2023 Oktochain Foundation Ltd
        </Footer>
      </Layout>
    </Layout>
  );
};

export default withRouter(Page);
