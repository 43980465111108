import img from "../components/img/IMG.jpeg"
import Grp from "../components/img/Group 184.png"
import Tata from "../components/img/TATA.jpeg"
import Tatai from "../components/img/Tata icon.png"
import TitleImage from "../components/img/TataTitle.png"
import TataChart1 from "../components/img/graph/Tatachart1.png"
import TataChart2 from "../components/img/graph/Tatachart2.png"
import TataChart3 from "../components/img/graph/Tatachart3.png"
import TataChart4 from "../components/img/graph/Tatachart4.png"
import Adani from "../components/img/Adaniport.jpeg"
// import Adani from "../components/img/Adani test 1.jpeg"
import AdaniI from "../components/img/Adani icon.png"
import TataMotor from "../components/img/TATA Motor.jpeg"
import TataMotor1 from "../components/img/Tata Motors 1.png"
import Bonds from "../components/img/Bonds.png"
import Bondsi from "../components/img/Bond icon.png"
import Bondsi2 from "../components/img/Bond icon2.png"
import NFT from "../components/img/NFT.png"
import NFTi from "../components/img/NFT icon.png"
import NFTBean from "../components/img/NFT Bean.png"
import NFTBeani from "../components/img/NFT Bean icon.png"
import Crowd2 from "../components/img/CAR img.jpeg"
import Crowd2i from "../components/img/crowd 2 i.png"
import BeanTitle from "../components/img/BeanTitle.png"
import BeanChart1 from "../components/img/graph/Beanchart1.png"
import BeanChart2 from "../components/img/graph/Beanchart2.png"
import Newitem1 from "../components/img/Newitem1.png"
import Newitem1i from "../components/img/Newicon 1.png"
import Newitem2 from "../components/img/Newitem 2.png"
import Newitem3 from "../components/img/Newitem3.png"
import Newitem2i from "../components/img/Newicon 2.png"
import Newitem4 from "../components/img/Newitem 4.png"
import Newitem3i from "../components/img/Newicon3.png"
import cov1 from "../components/img/cov1.png"
import cov2 from "../components/img/cov2.png"
import all1 from "../components/img/all1.png"
import all2 from "../components/img/all2.png"
import ed1 from "../components/img/ed1.png"
import ed2 from "../components/img/ed2.png"
import mtu1 from "../components/img/mtu1.png"
import mtu2 from "../components/img/mtu2.png"
import Atlis from "../components/img/AtlisAvatar.png"
import AtlisChart from "../components/img/graph/Atlischart.png"
import AtlisChart1 from "../components/img/graph/Atlischart1.png"
import AtlisChart2 from "../components/img/graph/Atlischart2.png"




export const Posts =
[
  
 
  {
    "blockchainName": "Ethereum",
    "likes": [],
    "assetType": "ERC-1155",
    "report": [],
    "tokenPrice": "1.08",
    "Price": "1.08",
    "city": "M CAP 100 M",
    "active": "true",
    "totalSupply": 10,
    "userId": {
      "following": [
        "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
        "blvdCFVpTwaJSTAVttm69drX7Ex1"
      ],
      "private": false,
      "followers": [],
      "userType": "",
      "lastName": "Jeba",
      "paymentDetails": "",
      "email": "palinpalin7@gmail.com",
      "kycVerified": false,
      "firstName": "Palin",
      "earnedPoints": 11,
      "active": "true",
      "imageUrl": Tatai,
      "titleImage": TitleImage,
      "token": "",
      "kyc": [],
      "dateOfBirth": "",
      "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr2",
      "updateDate": "2021-10-23T07:23:52.836Z",
      "creation": "2021-09-22T04:34:31.092Z",
      "userName": "Equity",
      "address": "",
      "gender": "",
      "emailVerified": false,
      "createDate": "2021-09-22T04:34:31.092Z",
      "updated": "2021-10-23T07:23:52.836Z",
      "profession": ""
    },
    "ipfsPath": Tata,
    "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd7",
    "tags": ["Equity","Long added","Listed for sale"],
    "tag":["Equity"],
    "tokenId": 175305624,
    "location": { "lng": -122.084, "lat": 37.421998333333335 },
    "createdAt": "2021-12-07T11:16:30.085Z",
    "comments": [],
    "attachments": [
      {
        "fileType": "image",
        "url": Tata
      }
    ],
    "stockAvailable": 10,
    "earnedCoins": 0,
    "updatedAt": "2021-12-07T11:16:30.085Z",
    "description": "Tata Consultancy Services is an IT solution provider.TCS offers a consulting-led, integrated portfolio of IT and IT-enabled services delivered through its unique Global Network Delivery Model, recognized as the benchmark of excellence in software development. The company also engagged in the business of Telecom, Retail and Distribution, Banking, Financial Services and Insurance.",
    "contractAddress": "0xfbEBe49e943B0e26554014F4eDBAb17fG2",
    "hide": [],
    "taggedUsers": [],
    "title": "Tata Consultancy Services",
    "isLiked": false,
    "priceHistory": TataChart1,
    "financials": TataChart2,
    "shareholding": TataChart3,
    "history": TataChart4,
  },
 
  {
    "blockchainName": "Ethereum",
    "likes": [],
    "assetType": "ERC-721",
    "report": [],
    "tokenPrice": "0.049",
    "Price": "0.049",
    "city": "$ 55.33",
    "active": "true",
    "totalSupply": 10,
    "userId": {
      "following": [
        "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
        "blvdCFVpTwaJSTAVttm69drX7Ex1"
      ],
      "private": false,
      "followers": [],
      "userType": "",
      "lastName": "Jeba",
      "paymentDetails": "",
      "email": "palinpalin7@gmail.com",
      "kycVerified": false,
      "firstName": "Palin",
      "earnedPoints": 11,
      "active": "true",
      "imageUrl": NFTBeani,
      "titleImage": BeanTitle,
      "token": "",
      "kyc": [],
      "dateOfBirth": "",
      "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr8",
      "updateDate": "2021-10-23T07:23:52.836Z",
      "creation": "2021-09-22T04:34:31.092Z",
      "userName": "NFT",
      "address": "",
      "gender": "",
      "emailVerified": false,
      "createDate": "2021-09-22T04:34:31.092Z",
      "updated": "2021-10-23T07:23:52.836Z",
      "profession": ""
    },
    "ipfsPath": "https://firebasestorage.googleapis.com/v0/b/nft-marketplace-b2448.appspot.com/o/file%2FgreenMonster.png?alt=media&token=ca1b72f5-2deb-4559-9100-3ae78065d570",
    "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd37",
    "tags": ["NFT","The lowest price","Listed for sale"],
    "tag":["NFT"],
    "tokenId": 123,
    "location": { "lng": -122.084, "lat": 37.421998333333335 },
    "createdAt": "2021-12-07T11:16:30.085Z",
    "comments": [],
    "attachments": [
      {
        "fileType": "image",
        "url": NFTBean
      }
    ],
    "stockAvailable": 10,
    "earnedCoins": 0,
    "updatedAt": "2021-12-07T11:16:30.085Z",
    "description": "The Mr Bean NFT collection is comprised of 2,222 unique NFTs commemorating the whimsical and whacky adventures of Mr Bean. All attributes are from the official Mr Bean animated series. The collection is Mr Bean's first step into the Web3 and Metaverse space brought to market through Fomo Lab. We have a lot more to come from the Mr Bean x Fomo Lab Collection.",
    "contractAddress": "0x06542165342165321sc651526153",
    "hide": [],
    "taggedUsers": [],
    "title": "Mr.Bean Official",
    "isLiked": false,
    "priceHistory": BeanChart1,
    "itemActivity": BeanChart2,
    // "shareholding": BeanChart3,
    // "history": BeanChart4,
  },
  {
    "blockchainName": "Ethereum",
    "likes": [],
    "assetType": "ERC-1155",
    "report": [],
    "tokenPrice": "Raised $10,568",
    "Price": "",
    "active": "false",
    "totalSupply": 10,
    "userId": {
      "following": [
        "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
        "blvdCFVpTwaJSTAVttm69drX7Ex456"
      ],
      "private": false,
      "followers": [],
      "userType": "",
      "lastName": "Jeba",
      "paymentDetails": "",
      "email": "palinpalin7@gmail.com",
      "kycVerified": false,
      "firstName": "Palin",
      "earnedPoints": 11,
      "active": "false",
      "imageUrl": Crowd2i,
      "titleImage": Atlis,
      "token": "",
      "kyc": [],
      "dateOfBirth": "",
      "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr8",
      "updateDate": "2021-10-23T07:23:52.836Z",
      "creation": "2021-09-22T04:34:31.092Z",
      "userName": "Crowd funding",
      "address": "",
      "gender": "",
      "emailVerified": false,
      "createDate": "2021-09-22T04:34:31.092Z",
      "updated": "2021-10-23T07:23:52.836Z",
      "profession": ""
    },
    "ipfsPath": "https://firebasestorage.googleapis.com/v0/b/nft-marketplace-b2448.appspot.com/o/file%2FgreenMonster.png?alt=media&token=ca1b72f5-2deb-4559-9100-3ae78065d570",
    "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd38",
    "tags": ["Crowd funding","Long added"],
    "tag":["Crowd funding"],
    "tokenId": 123,
    "location": { "lng": -122.084, "lat": 37.421998333333335 },
    "createdAt": "2021-12-07T11:16:30.085Z",
    "comments": [],
    "attachments": [
      {
        "fileType": "image",
        "url": Crowd2
      }
    ],
    "stockAvailable": 10,
    "earnedCoins": 0,
    "updatedAt": "2021-12-07T11:16:30.085Z",
    "description": "Developing the new standard of work, Atlis is building the technology ecosystem to power our lives. Designed without compromise, crafted with intention, the Atlis XT is the future of work. ATLIS is revolutionizing battery technology from the ground up with the world's first AMV Cube Cell, capable of charging from 0-100% in less than 15 minutes while providing consistent performance in any environment. The ATLIS Energy division, with over $300 million in potential battery cell and battery pack commitments, enables us to reimagine your everyday work truck.",
    "contractAddress": "0x06542165342165321sc651526153",
    "hide": [],
    "taggedUsers": [],
    "title": "Atlis Motor Vehicles",
    "raised": "Raised $ 10,568",
    "investors": "Investors 5885",
    "date": "Closing Date Dec 2022",
    "city": "Target 32 M+",
    "isLiked": false,
    "new": true,
    "benefits": AtlisChart,
    "funds": AtlisChart1,
    "timeline": AtlisChart2,
    
  },
  {
    "blockchainName": "Ethereum",
    "likes": [],
    "assetType": "ERC-1155",
    "report": [],
    "tokenPrice": " 450",
    "Price": "450",
    "city": "Target $ 2000",
    "active": "true",
    "totalSupply": 10,
    "userId": {
      "following": [
        "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
        "blvdCFVpTwaJSTAVttm69drX7Ex1"
      ],
      "private": false,
      "followers": [],
      "userType": "",
      "lastName": "Jeba",
      "paymentDetails": "",
      "email": "palinpalin7@gmail.com",
      "kycVerified": false,
      "firstName": "Palin",
      "earnedPoints": 11,
      "active": "true",
      "imageUrl": Grp,
      "titleImage": Grp,
      "token": "",
      "kyc": [],
      "dateOfBirth": "",
      "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr1",
      "updateDate": "2021-10-23T07:23:52.836Z",
      "creation": "2021-09-22T04:34:31.092Z",
      "userName": "Crowd funding ",
      "address": "",
      "gender": "",
      "emailVerified": false,
      "createDate": "2021-09-22T04:34:31.092Z",
      "updated": "2021-10-23T07:23:52.836Z",
      "profession": ""
    },
    "ipfsPath": img,
    "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd6",
    "tags": ["Crowd funding","Highest price","Long added"],
    "tag":["Crowd funding"],
    "tokenId": 123,
    "location": { "lng": -122.084, "lat": 37.421998333333335 },
    "createdAt": "2021-12-07T11:16:30.085Z",
    "comments": [],
    "attachments": [
      {
        "fileType": "image",
        "url": img
      }
    ],
    "stockAvailable": 10,
    "earnedCoins": 0,
    "updatedAt": "2021-12-07T11:16:30.085Z",
    "description": "Integer scelerisque a metus et viverra. In mollis tellus sit amet neque consectetur, sed tempus tellus convallis. Nulla at nulla tincidunt, pretium metus ac, pulvinar tortor. Praesent convallis tristique dolor vitae lacinia. Nullam posuere ipsum libero, et accumsan orci lacinia a. Proin arcu diam, scelerisque a viverra at, vulputate ut nisl.",
    "contractAddress": "0x06542165342165321sc651526153",
    "hide": [],
    "taggedUsers": [],
    "title": "The John Antony West Project",
    "isLiked": false
  },

    {
      "blockchainName": "Ethereum",
      "likes": [],
      "assetType": "ERC-1155",
      "report": [],
      "tokenPrice": "3.00",
      "Price": "3.00",
      "city": "0I 75%",
      "active": "true",
      "totalSupply": 10,
      "userId": {
        "following": [
          "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
          "blvdCFVpTwaJSTAVttm69drX7Ex1"
        ],
        "private": false,
        "followers": [],
        "userType": "",
        "lastName": "Jeba",
        "paymentDetails": "",
        "email": "palinpalin7@gmail.com",
        "kycVerified": false,
        "firstName": "Palin",
        "earnedPoints": 11,
        "active": "true",
        "imageUrl": Tatai,
        "titleImage": Tatai,
        "token": "",
        "kyc": [],
        "dateOfBirth": "",
        "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr4",
        "updateDate": "2021-10-23T07:23:52.836Z",
        "creation": "2021-09-22T04:34:31.092Z",
        "userName": "Derivaties - Futures ",
        "address": "",
        "gender": "",
        "emailVerified": false,
        "createDate": "2021-09-22T04:34:31.092Z",
        "updated": "2021-10-23T07:23:52.836Z",
        "profession": ""
      },
      "ipfsPath": "https://firebasestorage.googleapis.com/v0/b/nft-marketplace-b2448.appspot.com/o/file%2FgreenMonster.png?alt=media&token=ca1b72f5-2deb-4559-9100-3ae78065d570",
      "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd33",
      "tags": ["Derivatives","Long added"],
      "tag":["Derivatives"],
      "tokenId": 123,
      "location": { "lng": -122.084, "lat": 37.421998333333335 },
      "createdAt": "2021-12-07T11:16:30.085Z",
      "comments": [],
      "attachments": [
        {
          "fileType": "image",
          "url": TataMotor
        }
      ],
      "stockAvailable": 10,
      "earnedCoins": 0,
      "updatedAt": "2021-12-07T11:16:30.085Z",
      "description": "Integer scelerisque a metus et viverra. In mollis tellus sit amet neque consectetur, sed tempus tellus convallis. Nulla at nulla tincidunt, pretium metus ac, pulvinar tortor. Praesent convallis tristique dolor vitae lacinia. Nullam posuere ipsum libero, et accumsan orci lacinia a. Proin arcu diam, scelerisque a viverra at, vulputate ut nisl.",
      "contractAddress": "0x06542165342165321sc651526153",
      "hide": [],
      "taggedUsers": [],
      "title": "Tata Motors 28 JUL 2022",
      "isLiked": false
    },
    {
      "blockchainName": "Ethereum",
      "likes": [],
      "assetType": "ERC-1155",
      "report": [],
      "tokenPrice": "2.66",
      "Price": "2.66",
      "city": "MCAP 100 M",
      "active": "true",
      "totalSupply": 10,
      "userId": {
        "following": [
          "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
          "blvdCFVpTwaJSTAVttm69drX7Ex1"
        ],
        "private": false,
        "followers": [],
        "userType": "",
        "lastName": "Jeba",
        "paymentDetails": "",
        "email": "palinpalin7@gmail.com",
        "kycVerified": false,
        "firstName": "Palin",
        "earnedPoints": 11,
        "active": "true",
        "imageUrl": AdaniI,
        "titleImage": AdaniI,
        "token": "",
        "kyc": [],
        "dateOfBirth": "",
        "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr3",
        "updateDate": "2021-10-23T07:23:52.836Z",
        "creation": "2021-09-22T04:34:31.092Z",
        "userName": "Equity ",
        "address": "",
        "gender": "",
        "emailVerified": false,
        "createDate": "2021-09-22T04:34:31.092Z",
        "updated": "2021-10-23T07:23:52.836Z",
        "profession": ""
      },
      "ipfsPath": "https://firebasestorage.googleapis.com/v0/b/nft-marketplace-b2448.appspot.com/o/file%2FgreenMonster.png?alt=media&token=ca1b72f5-2deb-4559-9100-3ae78065d570",
      "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd8",
      "tags": ["Equity"],
      "tag":["Equity"],
      "tokenId": 123,
      "location": { "lng": -122.084, "lat": 37.421998333333335 },
      "createdAt": "2021-12-07T11:16:30.085Z",
      "comments": [],
      "attachments": [
        {
          "fileType": "image",
          "url": Adani
        }
      ],
      "stockAvailable": 10,
      "earnedCoins": 0,
      "updatedAt": "2021-12-07T11:16:30.085Z",
      "description": "Integer scelerisque a metus et viverra. In mollis tellus sit amet neque consectetur, sed tempus tellus convallis. Nulla at nulla tincidunt, pretium metus ac, pulvinar tortor. Praesent convallis tristique dolor vitae lacinia. Nullam posuere ipsum libero, et accumsan orci lacinia a. Proin arcu diam, scelerisque a viverra at, vulputate ut nisl.",
      "contractAddress": "0x06542165342165321sc651526153",
      "hide": [],
      "taggedUsers": [],
      "title": "Adani Ports & SEZ",
      "isLiked": false
    },
    {
      "blockchainName": "Ethereum",
      "likes": [],
      "assetType": "ERC-1155",
      "report": [],
      "tokenPrice": "3.00",
      "Price": "3.00",
      "city": "PCR 25%",
      "active": "true",
      "totalSupply": 10,
      "userId": {
        "following": [
          "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
          "blvdCFVpTwaJSTAVttm69drX7Ex1"
        ],
        "private": false,
        "followers": [],
        "userType": "",
        "lastName": "Jeba",
        "paymentDetails": "",
        "email": "palinpalin7@gmail.com",
        "kycVerified": false,
        "firstName": "Palin",
        "earnedPoints": 11,
        "active": "true",
        "imageUrl": Tatai,
        "titleImage": Tatai,
        "token": "",
        "kyc": [],
        "dateOfBirth": "",
        "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr5",
        "updateDate": "2021-10-23T07:23:52.836Z",
        "creation": "2021-09-22T04:34:31.092Z",
        "userName": "Derivaties - Options ",
        "address": "",
        "gender": "",
        "emailVerified": false,
        "createDate": "2021-09-22T04:34:31.092Z",
        "updated": "2021-10-23T07:23:52.836Z",
        "profession": ""
      },
      "ipfsPath": "https://firebasestorage.googleapis.com/v0/b/nft-marketplace-b2448.appspot.com/o/file%2FgreenMonster.png?alt=media&token=ca1b72f5-2deb-4559-9100-3ae78065d570",
      "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd34",
      "tags": ["Derivatives","Long added"],
      "tag":["Derivatives"],
      "tokenId": 123,
      "location": { "lng": -122.084, "lat": 37.421998333333335 },
      "createdAt": "2021-12-07T11:16:30.085Z",
      "comments": [],
      "attachments": [
        {
          "fileType": "image",
          "url": TataMotor1
        }
      ],
      "stockAvailable": 10,
      "earnedCoins": 0,
      "updatedAt": "2021-12-07T11:16:30.085Z",
      "description": "Integer scelerisque a metus et viverra. In mollis tellus sit amet neque consectetur, sed tempus tellus convallis. Nulla at nulla tincidunt, pretium metus ac, pulvinar tortor. Praesent convallis tristique dolor vitae lacinia. Nullam posuere ipsum libero, et accumsan orci lacinia a. Proin arcu diam, scelerisque a viverra at, vulputate ut nisl.",
      "contractAddress": "0x06542165342165321sc651526153",
      "hide": [],
      "taggedUsers": [],
      "title": "Tata Motors 28 JUL 2022 300PE",
      "isLiked": false
    },
    {
      "blockchainName": "Ethereum",
      "likes": [],
      "assetType": "ERC-1155",
      "report": [],
      "tokenPrice": "101",
      "Price": "101",
      "city": "Fund Value $ 56,000",
      "active": "true",
      "totalSupply": 10,
      "userId": {
        "following": [
          "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
          "blvdCFVpTwaJSTAVttm69drX7Ex1"
        ],
        "private": false,
        "followers": [],
        "userType": "",
        "lastName": "Jeba",
        "paymentDetails": "",
        "email": "palinpalin7@gmail.com",
        "kycVerified": false,
        "firstName": "Palin",
        "earnedPoints": 11,
        "active": "true",
        "imageUrl": Bondsi,
        "titleImage": Bondsi,
        "token": "",
        "kyc": [],
        "dateOfBirth": "",
        "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr6",
        "updateDate": "2021-10-23T07:23:52.836Z",
        "creation": "2021-09-22T04:34:31.092Z",
        "userName": "Bond ",
        "address": "",
        "gender": "",
        "emailVerified": false,
        "createDate": "2021-09-22T04:34:31.092Z",
        "updated": "2021-10-23T07:23:52.836Z",
        "profession": ""
      },
      "ipfsPath": "https://firebasestorage.googleapis.com/v0/b/nft-marketplace-b2448.appspot.com/o/file%2FgreenMonster.png?alt=media&token=ca1b72f5-2deb-4559-9100-3ae78065d570",
      "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd35",
      "tags": ["Bond","Highest price"],
      "tag":["Bond"],
      "tokenId": 123,
      "location": { "lng": -122.084, "lat": 37.421998333333335 },
      "createdAt": "2021-12-07T11:16:30.085Z",
      "comments": [],
      "attachments": [
        {
          "fileType": "image",
          "url": Bonds
        }
      ],
      "stockAvailable": 10,
      "earnedCoins": 0,
      "updatedAt": "2021-12-07T11:16:30.085Z",
      "description": "Integer scelerisque a metus et viverra. In mollis tellus sit amet neque consectetur, sed tempus tellus convallis. Nulla at nulla tincidunt, pretium metus ac, pulvinar tortor. Praesent convallis tristique dolor vitae lacinia. Nullam posuere ipsum libero, et accumsan orci lacinia a. Proin arcu diam, scelerisque a viverra at, vulputate ut nisl.",
      "contractAddress": "0x06542165342165321sc651526153",
      "hide": [],
      "taggedUsers": [],
      "title": "Edelweiss Housing Finance NCD Apr 2022",
      "isLiked": false
    },
    {
      "blockchainName": "Ethereum",
      "likes": [],
      "assetType": "ERC-1155",
      "report": [],
      "tokenPrice": "3.00",
      "Price": "3.00",
      "city": "56/200",
      "active": "true",
      "totalSupply": 10,
      "userId": {
        "following": [
          "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
          "blvdCFVpTwaJSTAVttm69drX7Ex1"
        ],
        "private": false,
        "followers": [],
        "userType": "",
        "lastName": "Jeba",
        "paymentDetails": "",
        "email": "palinpalin7@gmail.com",
        "kycVerified": false,
        "firstName": "Palin",
        "earnedPoints": 11,
        "active": "true",
        "imageUrl": NFTi,
        "titleImage": NFTi,
        "token": "",
        "kyc": [],
        "dateOfBirth": "",
        "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr7",
        "updateDate": "2021-10-23T07:23:52.836Z",
        "creation": "2021-09-22T04:34:31.092Z",
        "userName": "NFT ",
        "address": "",
        "gender": "",
        "emailVerified": false,
        "createDate": "2021-09-22T04:34:31.092Z",
        "updated": "2021-10-23T07:23:52.836Z",
        "profession": ""
      },
      "ipfsPath": "https://firebasestorage.googleapis.com/v0/b/nft-marketplace-b2448.appspot.com/o/file%2FgreenMonster.png?alt=media&token=ca1b72f5-2deb-4559-9100-3ae78065d570",
      "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd36",
      "tags": ["NFT","Long added"],
      "tag":["NFT"],
      "tokenId": 123,
      "location": { "lng": -122.084, "lat": 37.421998333333335 },
      "createdAt": "2021-12-07T11:16:30.085Z",
      "comments": [],
      "attachments": [
        {
          "fileType": "image",
          "url": NFT
        }
      ],
      "stockAvailable": 10,
      "earnedCoins": 0,
      "updatedAt": "2021-12-07T11:16:30.085Z",
      "description": "Integer scelerisque a metus et viverra. In mollis tellus sit amet neque consectetur, sed tempus tellus convallis. Nulla at nulla tincidunt, pretium metus ac, pulvinar tortor. Praesent convallis tristique dolor vitae lacinia. Nullam posuere ipsum libero, et accumsan orci lacinia a. Proin arcu diam, scelerisque a viverra at, vulputate ut nisl.",
      "contractAddress": "0x06542165342165321sc651526153",
      "hide": [],
      "taggedUsers": [],
      "title": "Bored Ape Yacht Club",
      "isLiked": false
    },
    {
      "blockchainName": "Ethereum",
      "likes": [],
      "assetType": "ERC-1155",
      "report": [],
      "tokenPrice": "",
      "Price": "",
      "city": "101",
      "active": "true",
      "totalSupply": 10,
      "userId": {
        "following": [
          "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
          "blvdCFVpTwaJSTAVttm69drX7Ex1"
        ],
        "private": false,
        "followers": [],
        "userType": "",
        "lastName": "Jeba",
        "paymentDetails": "",
        "email": "palinpalin7@gmail.com",
        "kycVerified": false,
        "firstName": "Palin",
        "earnedPoints": 11,
        "active": "true",
        "imageUrl": Newitem1i,
        "titleImage": Newitem1i,
        "token": "",
        "kyc": [],
        "dateOfBirth": "",
        "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr9",
        "updateDate": "2021-10-23T07:23:52.836Z",
        "creation": "2021-09-22T04:34:31.092Z",
        "userName": "Place a bid",
        "address": "",
        "gender": "",
        "emailVerified": false,
        "createDate": "2021-09-22T04:34:31.092Z",
        "updated": "2021-10-23T07:23:52.836Z",
        "profession": ""
      },
      "ipfsPath": "https://firebasestorage.googleapis.com/v0/b/nft-marketplace-b2448.appspot.com/o/file%2FgreenMonster.png?alt=media&token=ca1b72f5-2deb-4559-9100-3ae78065d570",
      "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd39",
      "tags": [""],
      "tag":[""],
      "tokenId": 123,
      "location": { "lng": -122.084, "lat": 37.421998333333335 },
      "createdAt": "2021-12-07T11:16:30.085Z",
      "comments": [],
      "attachments": [
        {
          "fileType": "image",
          "url": Newitem1
        }
      ],
      "stockAvailable": 10,
      "earnedCoins": 0,
      "updatedAt": "2021-12-07T11:16:30.085Z",
      "description": "Integer scelerisque a metus et viverra. In mollis tellus sit amet neque consectetur, sed tempus tellus convallis. Nulla at nulla tincidunt, pretium metus ac, pulvinar tortor. Praesent convallis tristique dolor vitae lacinia. Nullam posuere ipsum libero, et accumsan orci lacinia a. Proin arcu diam, scelerisque a viverra at, vulputate ut nisl.",
      "contractAddress": "0x06542165342165321sc651526153",
      "hide": [],
      "taggedUsers": [],
      "title": "Wisdom Tree Europe Dividend Fund",
      "eth":"1.02 ETH",
      "isLiked": false
    },
    {
      "blockchainName": "Ethereum",
      "likes": [],
      "assetType": "ERC-1155",
      "report": [],
      "tokenPrice": "",
      "Price": "",
      "city": "66",
      "active": "true",
      "totalSupply": 10,
      "userId": {
        "following": [
          
        ],
        "private": false,
        "followers": [],
        "userType": "",
        "lastName": "Jeba",
        "paymentDetails": "",
        "email": "palinpalin7@gmail.com",
        "kycVerified": false,
        "firstName": "Palin",
        "earnedPoints": 11,
        "active": "true",
        "imageUrl": Bondsi2,
        "titleImage": Bondsi2,
        "token": "",
        "kyc": [],
        "dateOfBirth": "",
        "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr10",
        "updateDate": "2021-10-23T07:23:52.836Z",
        "creation": "2021-09-22T04:34:31.092Z",
        "userName": "Place a bid",
        "address": "",
        "gender": "",
        "emailVerified": false,
        "createDate": "2021-09-22T04:34:31.092Z",
        "updated": "2021-10-23T07:23:52.836Z",
        "profession": ""
      },
      "ipfsPath": "https://firebasestorage.googleapis.com/v0/b/nft-marketplace-b2448.appspot.com/o/file%2FgreenMonster.png?alt=media&token=ca1b72f5-2deb-4559-9100-3ae78065d570",
      "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd40",
      "tags": [""],
      "tag":[""],
      "tokenId": 123,
      "location": { "lng": -122.084, "lat": 37.421998333333335 },
      "createdAt": "2021-12-07T11:16:30.085Z",
      "comments": [],
      "attachments": [
        {
          "fileType": "image",
          "url": Newitem2
        }
      ],
      "stockAvailable": 10,
      "earnedCoins": 0,
      "updatedAt": "2021-12-07T11:16:30.085Z",
      "description": "Integer scelerisque a metus et viverra. In mollis tellus sit amet neque consectetur, sed tempus tellus convallis. Nulla at nulla tincidunt, pretium metus ac, pulvinar tortor. Praesent convallis tristique dolor vitae lacinia. Nullam posuere ipsum libero, et accumsan orci lacinia a. Proin arcu diam, scelerisque a viverra at, vulputate ut nisl.",
      "contractAddress": "0x06542165342165321sc651526153",
      "hide": [],
      "taggedUsers": [],
      "title": "Edelweiss Broking Limited ",
      "eth":"2.05 ETH",
      "isLiked": false
    },
    {
      "blockchainName": "Ethereum",
      "likes": [],
      "assetType": "ERC-1155",
      "report": [],
      "tokenPrice": "",
      "Price": "",
      "city": "50",
      "active": "true",
      "totalSupply": 10,
      "userId": {
        "following": [
          "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
          "blvdCFVpTwaJSTAVttm69drX7Ex1"
        ],
        "private": false,
        "followers": [],
        "userType": "",
        "lastName": "Jeba",
        "paymentDetails": "",
        "email": "palinpalin7@gmail.com",
        "kycVerified": false,
        "firstName": "Palin",
        "earnedPoints": 11,
        "active": "true",
        "imageUrl": Newitem2i,
        "titleImage": Newitem2i,
        "token": "",
        "kyc": [],
        "dateOfBirth": "",
        "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr11",
        "updateDate": "2021-10-23T07:23:52.836Z",
        "creation": "2021-09-22T04:34:31.092Z",
        "userName": "Place a bid",
        "address": "",
        "gender": "",
        "emailVerified": false,
        "createDate": "2021-09-22T04:34:31.092Z",
        "updated": "2021-10-23T07:23:52.836Z",
        "profession": ""
      },
      "ipfsPath": "https://firebasestorage.googleapis.com/v0/b/nft-marketplace-b2448.appspot.com/o/file%2FgreenMonster.png?alt=media&token=ca1b72f5-2deb-4559-9100-3ae78065d570",
      "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd41",
      "tags": [""],
      "tag":[""],
      "tokenId": 123,
      "location": { "lng": -122.084, "lat": 37.421998333333335 },
      "createdAt": "2021-12-07T11:16:30.085Z",
      "comments": [],
      "attachments": [
        {
          "fileType": "image",
          "url": Newitem3
        }
      ],
      "stockAvailable": 10,
      "earnedCoins": 0,
      "updatedAt": "2021-12-07T11:16:30.085Z",
      "description": "Integer scelerisque a metus et viverra. In mollis tellus sit amet neque consectetur, sed tempus tellus convallis. Nulla at nulla tincidunt, pretium metus ac, pulvinar tortor. Praesent convallis tristique dolor vitae lacinia. Nullam posuere ipsum libero, et accumsan orci lacinia a. Proin arcu diam, scelerisque a viverra at, vulputate ut nisl.",
      "contractAddress": "0x06542165342165321sc651526153",
      "hide": [],
      "taggedUsers": [],
      "title": "Aether Industries Limited IPO",
      "eth":"0.08 ETH ",
      "isLiked": false
    },
    {
      "blockchainName": "Ethereum",
      "likes": [],
      "assetType": "ERC-1155",
      "report": [],
      "tokenPrice": "",
      "Price": "",
      "city": "50",
      "active": "true",
      "totalSupply": 10,
      "userId": {
        "following": [
          "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
          "blvdCFVpTwaJSTAVttm69drX7Ex1"
        ],
        "private": false,
        "followers": [],
        "userType": "",
        "lastName": "Jeba",
        "paymentDetails": "",
        "email": "palinpalin7@gmail.com",
        "kycVerified": false,
        "firstName": "Palin",
        "earnedPoints": 11,
        "active": "true",
        "imageUrl": Newitem3i,
        "titleImage": Newitem3i,
        "token": "",
        "kyc": [],
        "dateOfBirth": "",
        "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr12",
        "updateDate": "2021-10-23T07:23:52.836Z",
        "creation": "2021-09-22T04:34:31.092Z",
        "userName": "Place a bid",
        "address": "",
        "gender": "",
        "emailVerified": false,
        "createDate": "2021-09-22T04:34:31.092Z",
        "updated": "2021-10-23T07:23:52.836Z",
        "profession": ""
      },
      "ipfsPath": "https://firebasestorage.googleapis.com/v0/b/nft-marketplace-b2448.appspot.com/o/file%2FgreenMonster.png?alt=media&token=ca1b72f5-2deb-4559-9100-3ae78065d570",
      "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd42",
      "tags": [""],
      "tag":[""],
      "tokenId": 123,
      "location": { "lng": -122.084, "lat": 37.421998333333335 },
      "createdAt": "2021-12-07T11:16:30.085Z",
      "comments": [],
      "attachments": [
        {
          "fileType": "image",
          "url": Newitem4
        }
      ],
      "stockAvailable": 10,
      "earnedCoins": 0,
      "updatedAt": "2021-12-07T11:16:30.085Z",
      "description": "Integer scelerisque a metus et viverra. In mollis tellus sit amet neque consectetur, sed tempus tellus convallis. Nulla at nulla tincidunt, pretium metus ac, pulvinar tortor. Praesent convallis tristique dolor vitae lacinia. Nullam posuere ipsum libero, et accumsan orci lacinia a. Proin arcu diam, scelerisque a viverra at, vulputate ut nisl.",
      "contractAddress": "0x06542165342165321sc651526153",
      "hide": [],
      "taggedUsers": [],
      "title": "The John Antony West Project",
      "eth":"0.07 ETH ",
      "isLiked": false
    },


    {
      "blockchainName": "Ethereum",
      "likes": [],
      "assetType": "ERC-1155",
      "report": [],
      "tokenPrice": "",
      "Price": "",
      "city": "101",
      "active": "true",
      "totalSupply": 10,
      "userId": {
        "following": [
          "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
          "blvdCFVpTwaJSTAVttm69drX7Ex1"
        ],
        "private": false,
        "followers": [],
        "userType": "",
        "lastName": "Jeba",
        "paymentDetails": "",
        "email": "palinpalin7@gmail.com",
        "kycVerified": false,
        "firstName": "Palin",
        "earnedPoints": 11,
        "active": "true",
        "imageUrl": cov1,
        "titleImage": cov1,
        "token": "",
        "kyc": [],
        "dateOfBirth": "",
        "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr9",
        "updateDate": "2021-10-23T07:23:52.836Z",
        "creation": "2021-09-22T04:34:31.092Z",
        "userName": "Place a bid",
        "address": "",
        "gender": "",
        "emailVerified": false,
        "createDate": "2021-09-22T04:34:31.092Z",
        "updated": "2021-10-23T07:23:52.836Z",
        "profession": ""
      },
      "ipfsPath": "https://firebasestorage.googleapis.com/v0/b/nft-marketplace-b2448.appspot.com/o/file%2FgreenMonster.png?alt=media&token=ca1b72f5-2deb-4559-9100-3ae78065d570",
      "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd43",
      "tags": [""],
      "tag":[""],
      "tokenId": 123,
      "location": { "lng": -122.084, "lat": 37.421998333333335 },
      "createdAt": "2021-12-07T11:16:30.085Z",
      "comments": [],
      "attachments": [
        {
          "fileType": "image",
          "url": cov2
        }
      ],
      "stockAvailable": 10,
      "earnedCoins": 0,
      "updatedAt": "2021-12-07T11:16:30.085Z",
      "description": "Integer scelerisque a metus et viverra. In mollis tellus sit amet neque consectetur, sed tempus tellus convallis. Nulla at nulla tincidunt, pretium metus ac, pulvinar tortor. Praesent convallis tristique dolor vitae lacinia. Nullam posuere ipsum libero, et accumsan orci lacinia a. Proin arcu diam, scelerisque a viverra at, vulputate ut nisl.",
      "contractAddress": "0x06542165342165321sc651526153",
      "hide": [],
      "taggedUsers": [],
      "title": "Covestro",
      "erc": "ERC-44 ",
      "isLiked": false
    },


    {
      "blockchainName": "Ethereum",
      "likes": [],
      "assetType": "ERC-1155",
      "report": [],
      "tokenPrice": "",
      "Price": "",
      "city": "101",
      "active": "true",
      "totalSupply": 10,
      "userId": {
        "following": [
          "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
          "blvdCFVpTwaJSTAVttm69drX7Ex1"
        ],
        "private": false,
        "followers": [],
        "userType": "",
        "lastName": "Jeba",
        "paymentDetails": "",
        "email": "palinpalin7@gmail.com",
        "kycVerified": false,
        "firstName": "Palin",
        "earnedPoints": 11,
        "active": "true",
        "imageUrl": all1,
        "titleImage": all1,
        "token": "",
        "kyc": [],
        "dateOfBirth": "",
        "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr9",
        "updateDate": "2021-10-23T07:23:52.836Z",
        "creation": "2021-09-22T04:34:31.092Z",
        "userName": "Place a bid",
        "address": "",
        "gender": "",
        "emailVerified": false,
        "createDate": "2021-09-22T04:34:31.092Z",
        "updated": "2021-10-23T07:23:52.836Z",
        "profession": ""
      },
      "ipfsPath": "https://firebasestorage.googleapis.com/v0/b/nft-marketplace-b2448.appspot.com/o/file%2FgreenMonster.png?alt=media&token=ca1b72f5-2deb-4559-9100-3ae78065d570",
      "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd44",
      "tags": [""],
      "tag":[""],
      "tokenId": 123,
      "location": { "lng": -122.084, "lat": 37.421998333333335 },
      "createdAt": "2021-12-07T11:16:30.085Z",
      "comments": [],
      "attachments": [
        {
          "fileType": "image",
          "url": all2
        }
      ],
      "stockAvailable": 10,
      "earnedCoins": 0,
      "updatedAt": "2021-12-07T11:16:30.085Z",
      "description": "Integer scelerisque a metus et viverra. In mollis tellus sit amet neque consectetur, sed tempus tellus convallis. Nulla at nulla tincidunt, pretium metus ac, pulvinar tortor. Praesent convallis tristique dolor vitae lacinia. Nullam posuere ipsum libero, et accumsan orci lacinia a. Proin arcu diam, scelerisque a viverra at, vulputate ut nisl.",
      "contractAddress": "0x06542165342165321sc651526153",
      "hide": [],
      "taggedUsers": [],
      "title": "Allianz",
      "erc": "ERC-82 ",
      "isLiked": false
    },

    {
      "blockchainName": "Ethereum",
      "likes": [],
      "assetType": "ERC-1155",
      "report": [],
      "tokenPrice": "",
      "Price": "",
      "city": "101",
      "active": "true",
      "totalSupply": 10,
      "userId": {
        "following": [
          "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
          "blvdCFVpTwaJSTAVttm69drX7Ex1"
        ],
        "private": false,
        "followers": [],
        "userType": "",
        "lastName": "Jeba",
        "paymentDetails": "",
        "email": "palinpalin7@gmail.com",
        "kycVerified": false,
        "firstName": "Palin",
        "earnedPoints": 11,
        "active": "true",
        "imageUrl": ed1,
        "titleImage": ed1,
        "token": "",
        "kyc": [],
        "dateOfBirth": "",
        "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr9",
        "updateDate": "2021-10-23T07:23:52.836Z",
        "creation": "2021-09-22T04:34:31.092Z",
        "userName": "Place a bid",
        "address": "",
        "gender": "",
        "emailVerified": false,
        "createDate": "2021-09-22T04:34:31.092Z",
        "updated": "2021-10-23T07:23:52.836Z",
        "profession": ""
      },
      "ipfsPath": "https://firebasestorage.googleapis.com/v0/b/nft-marketplace-b2448.appspot.com/o/file%2FgreenMonster.png?alt=media&token=ca1b72f5-2deb-4559-9100-3ae78065d570",
      "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd45",
      "tags": [""],
      "tag":[""],
      "tokenId": 123,
      "location": { "lng": -122.084, "lat": 37.421998333333335 },
      "createdAt": "2021-12-07T11:16:30.085Z",
      "comments": [],
      "attachments": [
        {
          "fileType": "image",
          "url": ed2
        }
      ],
      "stockAvailable": 10,
      "earnedCoins": 0,
      "updatedAt": "2021-12-07T11:16:30.085Z",
      "description": "Integer scelerisque a metus et viverra. In mollis tellus sit amet neque consectetur, sed tempus tellus convallis. Nulla at nulla tincidunt, pretium metus ac, pulvinar tortor. Praesent convallis tristique dolor vitae lacinia. Nullam posuere ipsum libero, et accumsan orci lacinia a. Proin arcu diam, scelerisque a viverra at, vulputate ut nisl.",
      "contractAddress": "0x06542165342165321sc651526153",
      "hide": [],
      "taggedUsers": [],
      "title": "  EADS",
      "erc": "ERC-123 ",
      "isLiked": false
    },

    {
      "blockchainName": "Ethereum",
      "likes": [],
      "assetType": "ERC-1155",
      "report": [],
      "tokenPrice": "",
      "Price": "",
      "city": "101",
      "active": "true",
      "totalSupply": 10,
      "userId": {
        "following": [
          "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
          "blvdCFVpTwaJSTAVttm69drX7Ex1"
        ],
        "private": false,
        "followers": [],
        "userType": "",
        "lastName": "Jeba",
        "paymentDetails": "",
        "email": "palinpalin7@gmail.com",
        "kycVerified": false,
        "firstName": "Palin",
        "earnedPoints": 11,
        "active": "true",
        "imageUrl": mtu1,
        "titleImage": mtu1,
        "token": "",
        "kyc": [],
        "dateOfBirth": "",
        "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr9",
        "updateDate": "2021-10-23T07:23:52.836Z",
        "creation": "2021-09-22T04:34:31.092Z",
        "userName": "Place a bid",
        "address": "",
        "gender": "",
        "emailVerified": false,
        "createDate": "2021-09-22T04:34:31.092Z",
        "updated": "2021-10-23T07:23:52.836Z",
        "profession": ""
      },
      "ipfsPath": "https://firebasestorage.googleapis.com/v0/b/nft-marketplace-b2448.appspot.com/o/file%2FgreenMonster.png?alt=media&token=ca1b72f5-2deb-4559-9100-3ae78065d570",
      "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd46",
      "tags": [""],
      "tag":[""],
      "tokenId": 123,
      "location": { "lng": -122.084, "lat": 37.421998333333335 },
      "createdAt": "2021-12-07T11:16:30.085Z",
      "comments": [],
      "attachments": [
        {
          "fileType": "image",
          "url": mtu2
        }
      ],
      "stockAvailable": 10,
      "earnedCoins": 0,
      "updatedAt": "2021-12-07T11:16:30.085Z",
      "description": "Integer scelerisque a metus et viverra. In mollis tellus sit amet neque consectetur, sed tempus tellus convallis. Nulla at nulla tincidunt, pretium metus ac, pulvinar tortor. Praesent convallis tristique dolor vitae lacinia. Nullam posuere ipsum libero, et accumsan orci lacinia a. Proin arcu diam, scelerisque a viverra at, vulputate ut nisl.",
      "contractAddress": "0x06542165342165321sc651526153",
      "hide": [],
      "taggedUsers": [],
      "title": "MTU ",
      "erc": "ERC-44 ",
      "isLiked": false
    },
    {
      "blockchainName": "Ethereum",
      "likes": [],
      "assetType": "ERC-1155",
      "report": [],
      "tokenPrice": "1.08",
      "Price": "1.08",
      "city": "M CAP 100 M",
      "active": "true",
      "totalSupply": 10,
      "userId": {
        "following": [
          "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
          "blvdCFVpTwaJSTAVttm69drX7Ex1"
        ],
        "private": false,
        "followers": [],
        "userType": "",
        "lastName": "Jeba",
        "paymentDetails": "",
        "email": "palinpalin7@gmail.com",
        "kycVerified": false,
        "firstName": "Palin",
        "earnedPoints": 11,
        "active": "true",
        "imageUrl": Tatai,
        "titleImage": TitleImage,
        "token": "",
        "kyc": [],
        "dateOfBirth": "",
        "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr2",
        "updateDate": "2021-10-23T07:23:52.836Z",
        "creation": "2021-09-22T04:34:31.092Z",
        "userName": "TCS",
        "address": "",
        "gender": "",
        "emailVerified": false,
        "createDate": "2021-09-22T04:34:31.092Z",
        "updated": "2021-10-23T07:23:52.836Z",
        "profession": ""
      },
      "ipfsPath": Tata,
      "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd7",
      "tags": [""],
      "tag":["Equity"],
      "tokenId": 175305624,
      "location": { "lng": -122.084, "lat": 37.421998333333335 },
      "createdAt": "2021-12-07T11:16:30.085Z",
      "comments": [],
      "attachments": [
        {
          "fileType": "image",
          "url": Tata
        }
      ],
      "stockAvailable": 10,
      "earnedCoins": 0,
      "updatedAt": "2021-12-07T11:16:30.085Z",
      "description": "Tata Consultancy Services is an IT solution provider.TCS offers a consulting-led, integrated portfolio of IT and IT-enabled services delivered through its unique Global Network Delivery Model, recognized as the benchmark of excellence in software development. The company also engagged in the business of Telecom, Retail and Distribution, Banking, Financial Services and Insurance.",
      "contractAddress": "0xfbEBe49e943B0e26554014F4eDBAb17fG2",
      "hide": [],
      "taggedUsers": [],
      "title": "Tata Consultancy Services",
      "isLiked": false,
      "priceHistory": TataChart1,
      "financials": TataChart2,
      "shareholding": TataChart3,
      "history": TataChart4,
    },
    {
      "blockchainName": "Ethereum",
      "likes": [],
      "assetType": "ERC-1155",
      "report": [],
      "tokenPrice": "2.66",
      "Price": "2.66",
      "city": "MCAP 100 M",
      "active": "true",
      "totalSupply": 10,
      "userId": {
        "following": [
          "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
          "blvdCFVpTwaJSTAVttm69drX7Ex1"
        ],
        "private": false,
        "followers": [],
        "userType": "",
        "lastName": "Jeba",
        "paymentDetails": "",
        "email": "palinpalin7@gmail.com",
        "kycVerified": false,
        "firstName": "Palin",
        "earnedPoints": 11,
        "active": "true",
        "imageUrl": AdaniI,
        "titleImage": AdaniI,
        "token": "",
        "kyc": [],
        "dateOfBirth": "",
        "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr3",
        "updateDate": "2021-10-23T07:23:52.836Z",
        "creation": "2021-09-22T04:34:31.092Z",
        "userName": "Equity ",
        "address": "",
        "gender": "",
        "emailVerified": false,
        "createDate": "2021-09-22T04:34:31.092Z",
        "updated": "2021-10-23T07:23:52.836Z",
        "profession": ""
      },
      "ipfsPath": "https://firebasestorage.googleapis.com/v0/b/nft-marketplace-b2448.appspot.com/o/file%2FgreenMonster.png?alt=media&token=ca1b72f5-2deb-4559-9100-3ae78065d570",
      "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd8",
      "tags": [""],
      "tag":[""],
      "tokenId": 123,
      "location": { "lng": -122.084, "lat": 37.421998333333335 },
      "createdAt": "2021-12-07T11:16:30.085Z",
      "comments": [],
      "attachments": [
        {
          "fileType": "image",
          "url": Adani
        }
      ],
      "stockAvailable": 10,
      "earnedCoins": 0,
      "updatedAt": "2021-12-07T11:16:30.085Z",
      "description": "Integer scelerisque a metus et viverra. In mollis tellus sit amet neque consectetur, sed tempus tellus convallis. Nulla at nulla tincidunt, pretium metus ac, pulvinar tortor. Praesent convallis tristique dolor vitae lacinia. Nullam posuere ipsum libero, et accumsan orci lacinia a. Proin arcu diam, scelerisque a viverra at, vulputate ut nisl.",
      "contractAddress": "0x06542165342165321sc651526153",
      "hide": [],
      "taggedUsers": [],
      "title": "Adani Ports & SEZ",
      "isLiked": false
    },
    {
      "blockchainName": "Ethereum",
      "likes": [],
      "assetType": "ERC-721",
      "report": [],
      "tokenPrice": "0.049",
      "Price": "0.049",
      "city": "$ 55.33",
      "active": "true",
      "totalSupply": 10,
      "userId": {
        "following": [
          "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
          "blvdCFVpTwaJSTAVttm69drX7Ex1"
        ],
        "private": false,
        "followers": [],
        "userType": "",
        "lastName": "Jeba",
        "paymentDetails": "",
        "email": "palinpalin7@gmail.com",
        "kycVerified": false,
        "firstName": "Palin",
        "earnedPoints": 11,
        "active": "true",
        "imageUrl": NFTBeani,
        "titleImage": BeanTitle,
        "token": "",
        "kyc": [],
        "dateOfBirth": "",
        "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr8",
        "updateDate": "2021-10-23T07:23:52.836Z",
        "creation": "2021-09-22T04:34:31.092Z",
        "userName": "NFT",
        "address": "",
        "gender": "",
        "emailVerified": false,
        "createDate": "2021-09-22T04:34:31.092Z",
        "updated": "2021-10-23T07:23:52.836Z",
        "profession": ""
      },
      "ipfsPath": "https://firebasestorage.googleapis.com/v0/b/nft-marketplace-b2448.appspot.com/o/file%2FgreenMonster.png?alt=media&token=ca1b72f5-2deb-4559-9100-3ae78065d570",
      "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd37",
      "tags": ["","Recently added"],
      "tag":["NFT"],
      "tokenId": 123,
      "location": { "lng": -122.084, "lat": 37.421998333333335 },
      "createdAt": "2021-12-07T11:16:30.085Z",
      "comments": [],
      "attachments": [
        {
          "fileType": "image",
          "url": NFTBean
        }
      ],
      "stockAvailable": 10,
      "earnedCoins": 0,
      "updatedAt": "2021-12-07T11:16:30.085Z",
      "description": "The Mr Bean NFT collection is comprised of 2,222 unique NFTs commemorating the whimsical and whacky adventures of Mr Bean. All attributes are from the official Mr Bean animated series. The collection is Mr Bean's first step into the Web3 and Metaverse space brought to market through Fomo Lab. We have a lot more to come from the Mr Bean x Fomo Lab Collection.",
      "contractAddress": "0x06542165342165321sc651526153",
      "hide": [],
      "taggedUsers": [],
      "title": "Mr.Bean Official",
      "isLiked": false,
      "priceHistory": BeanChart1,
      "itemActivity": BeanChart2,
      // "shareholding": BeanChart3,
      // "history": BeanChart4,
    },
    {
      "blockchainName": "Ethereum",
      "likes": [],
      "assetType": "ERC-1155",
      "report": [],
      "tokenPrice": " 450",
      "Price": "450",
      "city": "Target $ 2000",
      "active": "true",
      "totalSupply": 10,
      "userId": {
        "following": [
          "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
          "blvdCFVpTwaJSTAVttm69drX7Ex1"
        ],
        "private": false,
        "followers": [],
        "userType": "",
        "lastName": "Jeba",
        "paymentDetails": "",
        "email": "palinpalin7@gmail.com",
        "kycVerified": false,
        "firstName": "Palin",
        "earnedPoints": 11,
        "active": "true",
        "imageUrl": Grp,
        "titleImage": Grp,
        "token": "",
        "kyc": [],
        "dateOfBirth": "",
        "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr1",
        "updateDate": "2021-10-23T07:23:52.836Z",
        "creation": "2021-09-22T04:34:31.092Z",
        "userName": "Crowd funding ",
        "address": "",
        "gender": "",
        "emailVerified": false,
        "createDate": "2021-09-22T04:34:31.092Z",
        "updated": "2021-10-23T07:23:52.836Z",
        "profession": ""
      },
      "ipfsPath": img,
      "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd6",
      "tags": ["","Recently added"],
      "tag":[""],
      "tokenId": 123,
      "location": { "lng": -122.084, "lat": 37.421998333333335 },
      "createdAt": "2021-12-07T11:16:30.085Z",
      "comments": [],
      "attachments": [
        {
          "fileType": "image",
          "url": img
        }
      ],
      "stockAvailable": 10,
      "earnedCoins": 0,
      "updatedAt": "2021-12-07T11:16:30.085Z",
      "description": "Integer scelerisque a metus et viverra. In mollis tellus sit amet neque consectetur, sed tempus tellus convallis. Nulla at nulla tincidunt, pretium metus ac, pulvinar tortor. Praesent convallis tristique dolor vitae lacinia. Nullam posuere ipsum libero, et accumsan orci lacinia a. Proin arcu diam, scelerisque a viverra at, vulputate ut nisl.",
      "contractAddress": "0x06542165342165321sc651526153",
      "hide": [],
      "taggedUsers": [],
      "title": "The John Antony West Project",
      "isLiked": false
    },
        {
      "blockchainName": "Ethereum",
      "likes": [],
      "assetType": "ERC-1155",
      "report": [],
      "tokenPrice": "Raised $10,568",
      "Price": "",
      "active": "true",
      "totalSupply": 10,
      "userId": {
        "following": [
          "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
          "blvdCFVpTwaJSTAVttm69drX7Ex456"
        ],
        "private": false,
        "followers": [],
        "userType": "",
        "lastName": "Jeba",
        "paymentDetails": "",
        "email": "palinpalin7@gmail.com",
        "kycVerified": false,
        "firstName": "Palin",
        "earnedPoints": 11,
        "active": "true",
        "imageUrl": Crowd2i,
        "titleImage": Atlis,
        "token": "",
        "kyc": [],
        "dateOfBirth": "",
        "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr8",
        "updateDate": "2021-10-23T07:23:52.836Z",
        "creation": "2021-09-22T04:34:31.092Z",
        "userName": "Crowd funding",
        "address": "",
        "gender": "",
        "emailVerified": false,
        "createDate": "2021-09-22T04:34:31.092Z",
        "updated": "2021-10-23T07:23:52.836Z",
        "profession": ""
      },
      "ipfsPath": "https://firebasestorage.googleapis.com/v0/b/nft-marketplace-b2448.appspot.com/o/file%2FgreenMonster.png?alt=media&token=ca1b72f5-2deb-4559-9100-3ae78065d570",
      "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd398",
      "tags": ["Recently added"],
      "tag":[""],
      "tokenId": 123,
      "location": { "lng": -122.084, "lat": 37.421998333333335 },
      "createdAt": "2021-12-07T11:16:30.085Z",
      "comments": [],
      "attachments": [
        {
          "fileType": "image",
          "url": Crowd2
        }
      ],
      "stockAvailable": 10,
      "earnedCoins": 0,
      "updatedAt": "2021-12-07T11:16:30.085Z",
      "description": "Developing the new standard of work, Atlis is building the technology ecosystem to power our lives. Designed without compromise, crafted with intention, the Atlis XT is the future of work. ATLIS is revolutionizing battery technology from the ground up with the world's first AMV Cube Cell, capable of charging from 0-100% in less than 15 minutes while providing consistent performance in any environment. The ATLIS Energy division, with over $300 million in potential battery cell and battery pack commitments, enables us to reimagine your everyday work truck.",
      "contractAddress": "0x06542165342165321sc651526153",
      "hide": [],
      "taggedUsers": [],
      "title": "Atlis Motor Vehicles",
      "raised": "$ 10,568",
      "investors": "5885",
      "date": "Dec 2022",
      "city": "Target 32 M+",
      "isLiked": false,
      "benefits": AtlisChart,
      "funds": AtlisChart1,
      "timeline": AtlisChart2,
      
     
    },


    {
      "blockchainName": "Ethereum",
      "likes": [],
      "assetType": "ERC-721",
      "report": [],
      "tokenPrice": "0.049",
      "Price": "0.049",
      "city": "$ 55.33",
      "active": "true",
      "totalSupply": 10,
      "userId": {
        "following": [
          "eTsl3Nap9dSm0HkLarpdOqtnBVH2",
          "blvdCFVpTwaJSTAVttm69drX7Ex1"
        ],
        "private": false,
        "followers": [],
        "userType": "",
        "lastName": "Jeba",
        "paymentDetails": "",
        "email": "palinpalin7@gmail.com",
        "kycVerified": false,
        "firstName": "Palin",
        "earnedPoints": 11,
        "active": "true",
        "imageUrl": NFTBeani,
        "titleImage": BeanTitle,
        "token": "",
        "kyc": [],
        "dateOfBirth": "",
        "id": "tEpaR4ssiVYFf7ZW63dG2XI0FKr8",
        "updateDate": "2021-10-23T07:23:52.836Z",
        "creation": "2021-09-22T04:34:31.092Z",
        "userName": "NFT",
        "address": "",
        "gender": "",
        "emailVerified": false,
        "createDate": "2021-09-22T04:34:31.092Z",
        "updated": "2021-10-23T07:23:52.836Z",
        "profession": ""
      },
      "ipfsPath": "https://firebasestorage.googleapis.com/v0/b/nft-marketplace-b2448.appspot.com/o/file%2FgreenMonster.png?alt=media&token=ca1b72f5-2deb-4559-9100-3ae78065d570",
      "id": "4a0b9e11-610d-4e0b-ad1b-3189906a4fd37",
      "tags": [""],
      "tag":[""],
      "tokenId": 123,
      "location": { "lng": -122.084, "lat": 37.421998333333335 },
      "createdAt": "2021-12-07T11:16:30.085Z",
      "comments": [],
      "attachments": [
        {
          "fileType": "image",
          "url": NFTBean
        }
      ],
      "stockAvailable": 10,
      "earnedCoins": 0,
      "updatedAt": "2021-12-07T11:16:30.085Z",
      "description": "The Mr Bean NFT collection is comprised of 2,222 unique NFTs commemorating the whimsical and whacky adventures of Mr Bean. All attributes are from the official Mr Bean animated series. The collection is Mr Bean's first step into the Web3 and Metaverse space brought to market through Fomo Lab. We have a lot more to come from the Mr Bean x Fomo Lab Collection.",
      "contractAddress": "0x06542165342165321sc651526153",
      "hide": [],
      "taggedUsers": [],
      "title": "Mr.Bean Official",
      "isLiked": false,
      "priceHistory": BeanChart1,
      "itemActivity": BeanChart2,
      // "shareholding": BeanChart3,
      // "history": BeanChart4,
    },
  ]