import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../redux";
import * as AWS from "@aws-sdk/client-elasticsearch-service";
import firebase from '../../services/firebaseServices'
import Logo from "../../../src/images/logo.png"
import Logo1 from "../../../src/images/logo1.png"
import User from "./User"
// import {GetUser} from '../../services/ApiServices'
// const client = new AWS.ElasticsearchService({ region: "us-east-1" });


const nav = [
  {
    url: "/",
    title: "Marketplace",
    id: "marketplace"
  },
  {
    url: "/collection",
    title: "Collections",
    id: "collection"
  },
  {
    url: "/faq",
    title: "FAQ",
    id: "how"
  },

];

const Headers = (props, { history , account, active, connect, logout }) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [visibleNav, setVisibleNav] = useState(false);
  const [search, setSearch] = useState("");
  const [isLogin, setIsLogin] = useState(false)
  const [user, setUser] = useState({ id: "" })
  const [msg, setMsg] = useState("")
  const [pathNum, setPathNum] = useState(0)
  // if (state.Posts.isSearchLoaded === false) {
  //   dispatch(getSearch());
  // }
  // if (state.Posts.isFirstLoaded === false) {
  //   dispatch(getPosts());
  // }
  useEffect(() => {
  }, [state.Posts.posts]);
  useEffect(() => {
  }, [state.Posts.searchposts]);
  useEffect(() => {
    setUser(state.User.user)
  }, [state.User.user]);
  const handleSubmit = (e) => {
    e.preventDefault();
    props.history.push('/Explore?search=' + search)
  };
  const userDetails = async (uid) => {
    dispatch(getUser(uid))
  }

  // const GetUser = () => {
  //   firebase.auth().onAuthStateChanged(function (user) {
  //     if (user) {
  //       setIsLogin(true)
  //       userDetails(user.uid)
  //     }
  //     else {
  //       setIsLogin(false)
  //       setUser()
  //     }
  //   });

  // }

  // useEffect(async () => {
  //   GetUser()
  // }, [])

  useEffect(() => {
    if (props.history) {
      let urlName = props.history.location.pathname.split("/");
      switch (urlName[1]) {
        case "":
          setPathNum(0);
          break;
        case "collection":
          setPathNum(1);
          break;
        case "faq":
          setPathNum(2);
          break;
        default:
          setPathNum(1000)
          break;
      }
    }
  })
  return (
    <OutsideClickHandler onOutsideClick={() => setVisibleNav(false)}>
      <header className={styles.header}>
        <div className={cn("container", styles.container)}>
          <Link className={styles.logo} to="/" onClick={()=> {setPathNum(0) }} >
            <img
            className={styles.logos} 
              src={Logo}
              alt="logo"
            />
            <img
            className={styles.logos1} 
              src={Logo1}
              alt="logo"
            />
          </Link>
          {/* <div className={styles.logo}></div> */}
          {/* <div style={{position:'relative',left:'-4vh',top:'0.8vh'}}className={styles.version}> */}
          {/* <div style={{color:'black'}}className={styles.details}>Dark Mode</div> */}
          {/* <Theme  className="theme-big" />
            </div> */}
          {/* <form className={styles.search} onSubmit={(e) => handleSubmit(e)}>
            <input
              className={styles.input}
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              name="search"
              placeholder="Select item search"
              // required
            />
            <button className={styles.result}>
              <Icon name="search" size="20" />
            </button>
          </form> */}
          <div className={cn(styles.wrapper, { [styles.active]: visibleNav })}>
            <span className={styles.nav}>
              {nav.map((x, index) => (
                <Link
                  className={pathNum === index ? styles.activeLink : styles.link}
                  onClick={() => { setVisibleNav(!visibleNav); setPathNum(index) }}
                  // activeClassName={styles.active}
                  to={x.url}
                  key={index}
                // spy={true} smooth={true} offset={-90} duration={500}
                >
                  {x.title}
                </Link>
              ))}
            </span>


            {/* <Link
            className={cn("button-small", styles.button)}
            to="/upload-details"
          >
            Upload
          </Link> */}
          </div>
          {/* {user  &&
        <Link
          className={cn("button-small", styles.button)}
          to="/upload-details"
        >
          Upload
        </Link>
        } */}
          {/* <Link
          className={cn("button-small", styles.button)}
          to="/connect-wallet"
        >
          Connect Wallet
        </Link> */}
         {/* <button className={cn("button", styles.loginButton)} onClick={() => { setMsg("login") }}>Login/SignIn</button> */}
          {/* {msg === "login" ?  <User className={styles.user} user={user} refresh={GetUser} /> : null } */}
          <User className={styles.user} 
            user={user}  
            // stick={stick}
            account={props.account}
            active={props.active}
            connect={props.connect}
            logout={props.logout}
            />
          <button
            className={cn(styles.burger, { [styles.active]: visibleNav })}
            onClick={() => setVisibleNav(!visibleNav)}
          ></button>
        </div>
      </header>
    </OutsideClickHandler>
  );
};

export default Headers; 