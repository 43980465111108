import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Description2.module.sass";
import Image from "../../../components/Image";

const Description = () => {
  return (
    <div className={styles.section}>
      <div className={cn("container", styles.container)}>
        <div className={styles.body}>
        <div className={styles.wrap}>
          {/* <div className={styles.stage}>DIGITAL EMPORIUM</div> */}
          <h1 className={cn("h1", styles.title)}>
          Welcome to the world’s largest alternative marketplace for
          <br></br>
          <span style={{color:'#BB1D55'}}>Digital Assets</span>
          </h1>
          <div className={styles.text}>
          A new marketplace to distribute Digital Securities and Digital Assets like Digital Equities, Digital Bonds, Futures and Derivatives, Private Placements and Crowd funding.
          <br></br>
          {/* <Link className={styles.learnmore} to="/Explore">
            Learn more... 
            </Link> */}
          </div>
          <div className={styles.btns}>
            <Link className={cn("button", styles.button)} to="/Explore">
            Explore 
            </Link>
            </div>
          {/* <div className={styles.btns}> */}

          {/* </div> */}
        </div>

          <div className={styles.preview}>
            <Image
              src="/images/content/Partners2.png"
              alt="Cubes"
              className={styles.image}
            />
          </div>
          {/* <div className={styles.preview}>
            <Image
              srcSet="/images/content/cube@2x.png 2x"
              srcSetDark="/images/content/cube-dark@2x.png 2x"
              src="/images/content/cube.png"
              srcDark="/images/content/cube-dark.png"
              alt="Cube"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Description;
