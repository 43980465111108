import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Selection.module.sass";
import Icon from "../../../components/Icon";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../components/Card";
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import Image from "../../../components/Image";


const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;


const Selection = () => (
    <div className={styles.container} >
    <h3 className={cn("h4", styles.title)}>Market Place For Digital Assets</h3>
<div className={styles.line}></div>
    <div className={styles.row}>
                <div className={styles.feature}>  
                  <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
                  <div>
                  <img  className={styles.img} src="/images/content/gr0.png" alt="" />
                  <p className={styles.text}>Digital marketplace</p>
                  <p className={styles.text1}>An alternative way to market the digital assets directly in this place.</p>
                  </div>
                  <div className={styles.line1}/>
                  </Reveal>                  
                </div>

                <div className={styles.feature}>  
                  <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
                  <div>
                  <img className={styles.img} srcSet="/images/content/gr2.png" src="/images/content/g2.png" alt="" />
                  <p className={styles.text}>For Buyers</p>
                  <p className={styles.text1}>Single destination to fine everything digital and what is in trend.</p>
                  </div>
                  <div className={styles.line1}/>
                  </Reveal>                 
                </div>

                <div className={styles.feature}>  
                  <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
                  <div>
                  <img className={styles.img} srcSet="/images/content/gr1.png" src="/images/content/g1.png" alt="" />
                  <p className={styles.text}>For Sellers</p>
                  <p className={styles.text1}>An alternative way to market the digital assets directly in this place.</p>
                  </div>
                  <div className={styles.line1}/>
                  </Reveal>                 
                </div>
  
    </div>
    </div>
);

export default Selection;
