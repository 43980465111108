import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./UploadDetails.module.sass";
import Dropdown from "../../components/Dropdown";
import Icon from "../../components/Icon";
import TextInput from "../../components/TextInput";
import TextArea from "../../components/TextArea";
// import Switch from "../../components/Switch";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import Preview from "./Preview";
import Cards from "./Cards";
import FolowSteps from "./FolowSteps";
import firebase from "../../services/firebaseServices";
import CreatableSelect from "react-select/creatable";
import { UpdateAsset } from "../../services/ApiServices";
// import { getPosts } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import "./index.css";
import { Row, Col } from "antd";
import OutsideClickHandler from "react-outside-click-handler";
import { AddCommnets, GetAssetById, GetUSD } from "../../services/ApiServices";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  Link,
} from "react-router-dom";
const royaltiesOptions = ["flower", "fruit", "tree", "pet", "work", "Art"];

const items = [
  {
    title: "Create collection",
    color: "#4BC9F0",
  },
  {
    title: "Crypto Legend - Professor",
    color: "#45B26B",
  },
  {
    title: "Crypto Legend - Professor",
    color: "#EF466F",
  },
  {
    title: "Legend Photography",
    color: "#9757D7",
  },
];
const options = [
  { label: "Art", value: "art" },
  { label: "Work", value: "work" },
  { label: "Games", value: "games" },
  { label: "Photography", value: "photography" },
  { label: "Music", value: "music" },
  { label: "Video", value: "video" },
];
const Upload = (props) => {
  // const darkMode = useDarkMode(false);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [royalties, setRoyalties] = useState(royaltiesOptions[0]);
  const [sale, setSale] = useState(true);
  const [price, setPrice] = useState("");
  const [asset, setAsset] = useState({});
  const { id } = useParams();
  const [locking, setLocking] = useState(false);
  const [file, setFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState();
  const [visibleModal, setVisibleModal] = useState(false);
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const [preview, setPreview] = useState("");
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [error, setError] = useState("");
  const [supply, setSupply] = useState("");
  const [stock, setStock] = useState("");
  const [blockchainName, setBlockchainName] = useState("");
  const [assetType, setAssetType] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [ipfsPath, setIpfsPath] = useState("");
  const [user, setUser] = useState({});
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    setUser(state.User.user);
  }, [state.User.user]);
  const titleFunction = async (e) => {
    setError("");
    setTitle(e.target.value);
  };
  const descriptionFunction = (e) => {
    setError("");
    setDescription(e.target.value);
  };
  const priceFunction = async (e) => {
    setError("");
    setPrice(e.target.value);
  };
  const supplyFunction = async (e) => {
    setError("");
    setSupply(e.target.value);
  };
  const stockFunction = async (e) => {
    setError("");
    setStock(e.target.value);
  };
  const blockchainNameFunction = async (e) => {
    setError("");
    setBlockchainName(e.target.value);
  };
  const assetTypeFunction = async (e) => {
    setError("");
    setAssetType(e.target.value);
  };
  const contractAddressFunction = async (e) => {
    setError("");
    setContractAddress(e.target.value);
  };
  const tokenIdFunction = async (e) => {
    setError("");
    setTokenId(e.target.value);
  };
  const ipfsPathFunction = async (e) => {
    setError("");
    setIpfsPath(e.target.value);
  };

  const handle = async (e) => {
    setError("");
    let file = e.target.files[0];
    setFile(file);

    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPreview(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setPreview("");
    }
  };

  const createItem = () => {
    if (!title) setError("Title is required!");
    else if (!description) setError("Description is required!");
    else if (selected.length <= 0) setError("Please select atleast one Tag!");
    else if (!price) setError("Price is required!");
    else if (!supply) setError("Supply is required!");
    else if (!stock) setError("Stock is required!");
    else if(!file) updatePost(asset.attachments[0].url)
    else upload();
  };
  const updatePost = async (url) => {
    var tags = [];
    selected.map((data) => {
      tags.push(data.label);
    });

    let result;
    let body = {
      title: title,
      description: description,
      attachments: [
        {
          fileType: "image",
          url: url,
        },
      ],
      tags: tags,
      userId: user.id,
      location: { lng: -122.084, lat: 37.421998333333335 },
      tokenPrice: parseFloat(price),
      totalSupply: parseInt(supply),
      stockAvailable: parseInt(stock),
    };
    try {
      result = await UpdateAsset(body,asset.id);
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        // dispatch(getPosts());
        console.log(result);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const upload = async () => {
    setIsLoading(true);
    let bucketName = "file";
    var storageRef = firebase.storage().ref(`${bucketName}/${file.name}`);
    let uploadTask = storageRef.put(file);
    await uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      function (snapshot) {
        var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setLoadingPercentage(Math.round(percent - 8));
      },
      () => {},
      async (file) => {
        FirebaseUpload();
      }
    );
  };
  useEffect(() => {
    setUser(state.User.user);
  }, [state.User.user]);

  useEffect(() => {
    setIsLoading(true);
    getAsset();
  }, []);

  const getAsset = async () => {
    setIsLoading(true);
    let result;
    try {
      result = await GetAssetById(id);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }

    try {
      if (result.success) {
        setIsLoading(false);
        setAsset(result.posts[0]);
        setTitle(result.posts[0].title);
        setDescription(result.posts[0].description);
        result.posts[0].tags.map(data=>{
          setSelected(selected => [...selected, {label:data,value:data}])

        })
        setPrice(result.posts[0].tokenPrice);
        setSupply(result.posts[0].totalSupply);
        setStock(result.posts[0].stockAvailable);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const FirebaseUpload = () => {
    let storageRef = firebase.storage().ref();
    storageRef
      .child("/file/" + file.name)
      .getDownloadURL()
      .then((url) => {
        setLoadingPercentage(100);

        if (url) {
          setUrl(url);
          updatePost(url);
        } else {
          setIsLoading(true);
        }
      })
      .catch((err) => {
        setIsLoading(true);
        console.log(err.message);
      });
  };
  return (
    <>
      <OutsideClickHandler>
        {isLoading && <Loader className={styles.loader} />}
        {asset.id && (
          <div className={cn("section", styles.section)}>
            <div className={cn("container", styles.container)}>
              <div className={styles.heading}>
                <Link
                  to={"/Seller-item/" + asset.id}
                  className={cn("button-stroke", styles.button)}
                >
                  <Icon name="arrow-prev" />
                </Link>
                <div className={styles.title}>NFT Edit</div>
              </div>

              <Row wrap="false" gutter={24}>
                <Col span={12} className={styles.card}>
                  <div className={styles.wrapper}>
                    <form className={styles.form} action="">
                      <div className={styles.list}>
                        <div className={styles.item}>
                          <div className={styles.category}>Upload file</div>
                          <div className={styles.note}>
                            Drag or choose your file to upload
                          </div>
                          <div className={styles.file}>
                            <input
                              className={styles.load}
                              type="file"
                              onChange={handle}
                            />
                            <div className={styles.icon}>
                              <Icon name="upload-file" size="24" />
                            </div>
                            <div className={styles.format}>
                              PNG, GIF, JPEG, MP4 or MP3. Max 1Gb.
                            </div>
                          </div>
                        </div>
                        <div className={styles.item}>
                          <div className={styles.fieldset}>
                            <TextInput
                              className={styles.field}
                              label="Item title"
                              name="Item"
                              value={title}
                              type="text"
                              onChange={(e) => {
                                titleFunction(e);
                              }}
                              placeholder="Title"
                              required
                            />
                            <TextArea
                              className={styles.field}
                              label="Description"
                              name="Description"
                              value={description}
                              onChange={(e) => {
                                descriptionFunction(e);
                              }}
                              type="text"
                              placeholder="Description"
                              required
                            />
                            {/* <div className={styles.row}>
                      <div className={styles.col}>
                      <MultiSelect
                          options={options}
                          value={selected}
                          onChange={setSelected}
                          labelledBy="Select"
                        />
                      </div>
                      </div>
                     */}

                            <div className={styles.field}>
                              <div className={styles.label}>Tags</div>

                              <Select
                                // className={
                                //   darkMode.value
                                //     ? "dark select"
                                //     : "light select"
                                // }
                                options={options}
                                isMulti
                                styles={styles.dropdown}
                                value={selected}
                                onChange={setSelected}
                                classNamePrefix="react-select"
                              />
                              <br />
                            </div>
                            <TextInput
                              className={styles.field}
                              label="Price"
                              name="Price"
                              value={price}
                              onChange={(e) => {
                                priceFunction(e);
                              }}
                              type="number"
                              placeholder="3.5"
                              required
                            />
                            <TextInput
                              className={styles.field}
                              label="Supply"
                              name="Supply"
                              value={supply}
                              onChange={(e) => {
                                supplyFunction(e);
                              }}
                              type="number"
                              placeholder="10"
                              required
                            />
                            <TextInput
                              className={styles.field}
                              label="Available stock"
                              name="stock"
                              value={stock}
                              onChange={(e) => {
                                stockFunction(e);
                              }}
                              type="number"
                              placeholder="10"
                              required
                            />
                            {/* <TextInput
                              className={styles.field}
                              label="Blockchain Name"
                              name="blockchainName"
                              value={blockchainName}
                              onChange={(e) => {
                                blockchainNameFunction(e);
                              }}
                              type="text"
                              placeholder="Polygon"
                              required
                            /> */}
                            {/* <TextInput
                              className={styles.field}
                              label="Asset Type"
                              name="assetType"
                              value={assetType}
                              onChange={(e) => {
                                assetTypeFunction(e);
                              }}
                              type="text"
                              placeholder="ERC-721"
                              required
                            />
                            <TextInput
                              className={styles.field}
                              label="Contract Address"
                              name="contractAddress"
                              value={contractAddress}
                              onChange={(e) => {
                                contractAddressFunction(e);
                              }}
                              type="text"
                              placeholder="0x06542165342165321sc651526154"
                              required
                            />
                            <TextInput
                              className={styles.field}
                              label="Token Id"
                              name="tokenId"
                              value={tokenId}
                              onChange={(e) => {
                                tokenIdFunction(e);
                              }}
                              type="text"
                              placeholder="0x06542165342165321sc651526154"
                              required
                            />
                            <TextInput
                              className={styles.field}
                              label="IPFS Path"
                              name="ipfsPath"
                              value={ipfsPath}
                              onChange={(e) => {
                                ipfsPathFunction(e);
                              }}
                              type="text"
                              placeholder="0x06542165342165321sc651526153user"
                              required
                            /> */}
                          </div>
                        </div>
                        {error && <p className={styles.error}>{error}</p>}
                      </div>
                      <div className={styles.options}></div>
                      <div className={styles.foot}>
                        <button
                          className={cn(
                            "button-stroke tablet-show",
                            styles.button
                          )}
                          onClick={() => setVisiblePreview(true)}
                          type="button"
                        >
                          Preview
                        </button>
                        <button
                          className={cn("button", styles.button)}
                          onClick={() => createItem()}
                          type="button"
                        >
                          <span>Save Changes</span>
                          <Icon name="arrow-next" size="10" />
                        </button>
                        {isLoading && (
                          <div className={styles.saving}>
                            <Loader className={styles.loader} />
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </Col>
                <Col span={11} className={styles.card}>
                  <h2>Preview</h2>
                  <div className={styles.preview}>
                    {asset.attachments[0].fileType == "image" ? (
                      <>
                        <img
                          className={styles.bg}
                          srcSet={`${
                            preview ? preview : asset.attachments[0].url
                          } 2x`}
                          src={preview ? preview : asset.attachments[0].url}
                          alt="Card"
                        />
                      </>
                    ) : (
                      <video
                        autoPlay
                        muted
                        loop
                        src={asset.attachments[0].url}
                        type="video/mp4"
                      />
                    )}
                    <div className={styles.field}>{title}</div>
                    <div className={styles.value}>
                      Edition {stock} of {supply}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </OutsideClickHandler>

      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <FolowSteps className={styles.steps} />
      </Modal>
    </>
  );
};

export default Upload;
