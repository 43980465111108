import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./ProfileEdit.module.sass";
import Control from "../../components/Control";
import TextInput from "../../components/TextInput";
import Dropdown from "../../components/Dropdown";
import Icon from "../../components/Icon";
import { userUpdate } from "../../services/ApiServices";
// import firebase from "../../services/firebaseServices";
import { getDatabase, ref, child, push, update } from "firebase/database";
import profile from "../../components/image.png";
import { getStorage, ref as referance, uploadBytes,getMetadata  } from "firebase/storage";
import { Image } from "antd";
// import storage from "../../services/firebaseServices"///

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Edit Profile",
  },
];
const options = ["Male","Female"];

const ProfileEdit = (props) => {
  const [direction, setDirection] = useState(options[0]);
  const [preview, setPreview] = useState("");
  const [file, setFile] = useState();
  const [isLoading,setIsLoading] = useState(false)
  const [username, setUsername] = useState(localStorage.getItem("name"));
  const [userLastName ,setUserLastName] = useState(localStorage.getItem("lastName"))
  const [mobNo,setMobErrorNo] = useState(localStorage.getItem("mobno"))
  const [mobno,setMobno] = useState(localStorage.getItem("mobno"))
  const [url,setUrl] = useState(localStorage.getItem("url"))
  const [emailId, setEmailId] = useState(localStorage.getItem("email"));
  const [mobileNumber, setmMbileNumber] = useState(localStorage.getItem("mobileNo"));
  const [uid, setUid] = useState("")
  const storage = getStorage();

  const createPost=async(url)=>{
    
    let result;
    let body={

    }
    try {
      result = await userUpdate(body);
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        setFile("")
        setPreview("")
       
        console.log(result);
    setIsLoading(false)
      }
    } catch (e) {
    setIsLoading(false)
      console.log(e);
    }
  }


  const handle = async (e) => {
    let file = e.target.files[0];
    setFile(file);

    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPreview(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setPreview("");
    }
  };
  const upload = async () => {
    setIsLoading(true)
    let bucketName = "file";
    const storageRef = referance(storage, file.name);
    uploadBytes(storageRef, file).then((snapshot) => {
    const url= "https://firebasestorage.googleapis.com/v0/b/web3nft-283d2.appspot.com/o/"+snapshot.metadata.fullPath+"?alt=media&token="
    setUrl(url)
    console.log(url);
    localStorage.setItem("url",url)
    props.onClose()
      console.log('Uploaded a blob or file!',snapshot);
    });
    // const imagesRef = ref(storage, file);
    // storageRef.name === imagesRef.name;           // true
    // storageRef.fullPath === imagesRef.fullPath; 
// console.log(imagesRef);
    // var storageRef = firebase.storage().ref(`${bucketName}/${file.name}`);
    // let uploadTask = storageRef.put(file);
    // await uploadTask.on(
    //   firebase.storage.TaskEvent.STATE_CHANGED,
    //   function (snapshot) {
    //     var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //     // setLoadingPercentage(Math.round(percent - 8));
    //   },
    //   () => {},
    //   async (file) => {
    //     // FirebaseUpload();
    //   }
    // );
  };

  // const FirebaseUpload = () => {
  //   let storageRef = firebase.storage().ref();
  //   storageRef
  //     .child("/file/" + file.name)
  //     .getDownloadURL()
  //     .then((url) => {
  //       // setLoadingPercentage(100);

  //       if (url) {
  //         setUrl(url);
  //         writeNewPost(uid)
  //         // createPost(url);
  //       } else {
  //         setIsLoading(true);
  //       }
  //     })
  //     .catch((err) => {
  //       setIsLoading(true);
  //       console.log(err.message);
  //     });
  // };

  function writeNewPost(uid, url) {
    const db = getDatabase();
  
    // A post entry.
    const postData = {
      profileImage: url
    };
  console.log("profileImage")
    // Get a key for a new Post.
    const newPostKey = push(child(ref(db), 'users')).key;
  
    // Write the new post's data simultaneously in the posts list and the user's post list.
    const updates = {};
    updates[ '/Master/Address/'+ newPostKey] = postData;
    console.log(postData)
    // updates['/user-posts/' + uid + '/' + newPostKey] = postData;
  
    return update(ref(db), updates);
  }

  useEffect(() => {
    let uid = localStorage.getItem("uid");
    localStorage.setItem("name", username);
    localStorage.setItem("lastName",userLastName)
    localStorage.setItem("email", emailId);
    localStorage.setItem("mobileNo", mobileNumber);
    setUid(uid);
  }, [uid]);
  return (
    <div className={styles.page}>
      {/* <Control className={styles.control} item={breadcrumbs} /> */}
      <div className={cn("section-pt80", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.top}>
            <h1 className={cn("h4", styles.title)}>Edit profile</h1>
            {/* <div className={styles.info}>
              You can set preferred display name, create{" "}
              <strong>your profile URL</strong> and manage other personal
              settings.
            </div> */}
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.user}>
                <div className={styles.avatar}>
                {preview ? <img src={preview} alt="Avatar" />
                : <img src={profile} alt="Avatar" />}
                </div>
                <div className={styles.details}>
                  <div className={styles.stage}>Profile photo</div>
                  <div className={styles.text}>
                    We recommend an image of at least 400x400. 
                    {/* Gifs work too{" "}
                    <span role="img" aria-label="hooray">
                      🙌
                    </span> */}
                  </div>
                  <div className={styles.file}>
                    <button
                      className={cn(
                        "button-stroke button-small",
                        styles.button
                      )}
                      styles={{cursor:"pointer"}}
                    >
                      Upload
                    </button>
                    <input className={styles.load} type="file" onChange={handle}  />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.list}>
                <div className={styles.item}>
                 <div className={styles.name}>
            <div className={styles.heading}>FirstName:</div>      
                  <div className={styles.edit}>
                  <div className={styles.editname}>
                  {username} 
                  </div>
               <div className={styles.lastname}> LastName:</div></div>
                  <div className={styles.editname1}>
                  {userLastName}
                  </div>
                  </div>
                 
               <div style={{display: "flex",marginTop:"14px"}}>  
               <div className={styles.heading}>Email:</div>  <div className={styles.editname2}>
                    {emailId}
                    </div>
                    <div className={styles.headings}>Mobile No:</div>  <div className={styles.editname_2}>
                    {mobileNumber}
                    </div>
                    </div>
                  {/* <div className={styles.category}>Account info</div> */}
                   {/* <div className={styles.fieldset}> */}
                    {/* <TextInput
                      className={styles.field}
                      label="First name"
                      name="Name"
                      type="text"
                      // placeholder="Enter your display name"
                      required
                    />
                    <TextInput
                      className={styles.field}
                      label="last name"
                      name="Url"
                      type="text"
                      // placeholder="Enter your display name"
                      required
                    />  */}
                    {/* <Dropdown
                    className={ styles.dropdown}
                    value={direction}
                    setValue={setDirection}
                    options={options}
                  /> */}
                  {/* </div>  */}
                </div>
                {/* <div className={styles.item}>
                  <div className={styles.category}>Social</div>
                  <div className={styles.fieldset}>
                    <TextInput
                      className={styles.field}
                      label="portfolio or website"
                      name="Portfolio"
                      type="text"
                      placeholder="Enter URL"
                      required
                    />
                    <div className={styles.box}>
                      <TextInput
                        className={styles.field}
                        label="twitter"
                        name="Twitter"
                        type="text"
                        placeholder="@twitter username"
                        required
                      />
                      <button
                        className={cn(
                          "button-stroke button-small",
                          styles.button
                        )}
                      >
                        Verify account
                      </button>
                    </div>
                  </div>
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                  >
                    <Icon name="plus-circle" size="16" />
                    <span>Add more social account</span>
                  </button>
                </div>
              */}
              </div>
              {/* <div className={styles.note}>
                To update your settings you should sign message through your
                wallet. Click 'Update profile' then sign the message
              </div> */}
              <div className={styles.btns}>
                <button className={cn("button", styles.button)} onClick={()=>{upload()}}>
                  Update Profile
                </button>
                {/* <button className={styles.clear}>
                  <Icon name="circle-close" size="24" />
                  Clear all
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileEdit;
