import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './Description.module.sass';
import ManImage from '../../../images/manimg.png';
import Slider from 'react-slick';
import Bean from '../../../images/bean.png';
import Rat from '../../../images/rat.png';
import Monkey from '../../../images/monkey.png';
import BeanLogo from '../../../images/beanlogo.png';
import Moonbirds from '../../../images/moonbirds.png';
import Bamboo from '../../../images/bamboo.png';
import Collections from '../Collections';
import TextInput from '../../../components/TextInput';
import Polygon from '../../../images/polygon.png';
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from 'react-icons/md';
import { assets } from '../../../data/assets';
import {
  getAssets,
  Getcollections,
  GetUSD,
  GetMaticPrice,
} from '../../../services/ApiServices';
import Card from '../../../components/Card';
import { nfts } from '../../../data/nfts';
import {
  GetTrendingCollection,
  GetTopNfts,
  GetTopNftsByTags,
  CreateUser,
} from '../../../services/ApiServices';
import Loader from '../../../components/Loader';
import { useSnackbar } from 'notistack';
import { Spin } from 'antd';

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Description = (props) => {
  const [value, setValue] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [viewAllAsset, setViewAllAssets] = useState(false);
  const [assetss, setAssets] = useState([]);
  const [allAssets, setAllAssets] = useState([]);
  const [asset, setAsset] = useState([]);
  const [asset1, setAsset1] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const settings = {
    // speed: 800,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    centerMode: true,
    variableWidth: true,
    variableHeight: true,
    focusOnSelect: true,
    cssEase: 'linear',
    autoplay: true,
    nextArrow: (
      <SlickArrow>
        <MdOutlineArrowForwardIos size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <MdOutlineArrowBackIosNew size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 1340,
        settings: {
          autoPlay: true,
          slidesToShow: 3,
          centerMode: true,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          autoPlay: true,
          slidesToShow: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          infinite: true,
          centerMode: true,
        },
      },
    ],
  };

  const emailFunctions = async (e) => {
    setEmailError('');
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setEmail('');
      setEmailError('Please enter the valid email');
    } else {
      setEmailError('');
      setEmail(e.target.value);
    }
  };

  const getUSD = async () => {
    let result;
    try {
      result = await GetMaticPrice();
    } catch (e) {
      console.log(e);
    }

    try {
      if (result) {
        setValue(result.USD);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUSD();
  }, []);
  // const navLinks = [
  //   { x: "AR Backgrounds", tags: "AR Backgrounds" },
  //   { x: "AR Avatars", tags: "AR Avatars" },
  //   { x: "Face Swaps", tags: "Face Swaps" },
  //   { x: "Filters", tags: "Filters" },
  //   { x: "SETVI", tags: "Setvi" },
  // ];

  const filter = (value) => {
    let asset = [];
    if (value !== 'SETVI') {
      allAssets.map((data) => {
        data.tags.map((tag) => {
          if (tag === value) {
            asset.push(data);
          }
          console.log(data);
        });
      });
      setAssets(asset);
    } else {
      setAssets(allAssets);
    }
  };
  // useEffect(() => {
  //   setAssets(nfts);
  //   setAllAssets(nfts);
  // }, [nfts]);
  const getTrending = async () => {
    setLoading(true);
    let result;
    try {
      result = await Getcollections();
    } catch (e) {
      console.log(e);
    }
    try {
      if (result.success) {
        setLoading(false);
        setAsset(result.collection.docs);
      }
    } catch (e) {
      setLoading(true);
      console.log(e);
    }
  };
  // const getNft = async () => {
  //   setLoading(true)
  //   let result;
  //   try {
  //     result = await GetTopNfts(page,limit);
  //   } catch (e) {
  //     console.log(e);

  //   }
  //   try {
  //     if (result.success) {
  //       setLoading(false)
  //       setAsset1(result.asset.docs);
  //     }
  //   } catch (e) {
  //     setLoading(false)
  //     console.log(e);
  //   }
  // };
  const getNft = async () => {
    let result;
    try {
      result = await getAssets(page, limit);
    } catch (e) {
      console.log(e);
    }
    try {
      if (result.success) {
        setAsset1(result.asset.docs);
        // console.log(result)
      }
    } catch (e) {
      console.log(e);
    }
  };
  const createUser = async () => {
    setIsLoading(true);
    let result;
    let body = {
      email: email,
    };
    if (!email) {
      setEmailError('Email is required');
      setIsLoading(false);
    } else {
      try {
        result = await CreateUser(body);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        return enqueueSnackbar('Email already exists', { variant: 'error' });
      }
      try {
        if (result) {
          console.log(result);
          setIsLoading(false);
          return enqueueSnackbar(result.message, { variant: 'success' });
        }
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        return enqueueSnackbar('Email already exists', { variant: 'error' });
      }
    }
  };

  useEffect(() => {
    getTrending();
    getNft();
  }, []);
  return (
    <div className={styles.section}>
      <div className={cn('container', styles.container1)} id="home">
        <div className={styles.wrap}>
          <h1 className={cn('h1', styles.title)}>
            Discover collect,
            <br />
            and utilize <span style={{ color: '#4F42C9' }}>NFTs</span>
          </h1>
          <div className={styles.text}>
            {/* Welcome to the the Capering kids: Building the future at Karma
            collection by Priyanshi Patel. Each photograph signifies an
            underlying message. Each purchase contributes a bit to the future of
            the next generation */}
          </div>
          <div className={styles.btns}>
            <Link className={cn('button', styles.button)} to="/collection">
              Explore Collections
            </Link>
          </div>
        </div>
        <div className={styles.preview}>
          <img src={ManImage} alt="Cubes" className={styles.manImg} />
        </div>
      </div>
      <>
        <div id="marketplace" className={cn('container', styles.container)}>
          <h2 className={styles.heading} style={{ marginBottom: '35px' }}>
            Trending Collections
          </h2>
          {loading ? (
            <Loader className={styles.loader} />
          ) : (
            <div className={styles.wrapper}>
              <Slider className="popular-slider" {...settings}>
                {asset.map((x, index) => (
                  <div className={styles.slide} key={index}>
                    <div className={styles.item}>
                      <div className={styles.body}>
                        <div className={styles.avatar}>
                          <img src={x.featuredImage} alt="Avatar" />
                        </div>
                        <div className={styles.collection}>
                          <div className={styles.imgFlex}>
                            <img
                              src={x.logo}
                              alt="img"
                              className={styles.collectionImg}
                            />
                            <div className={styles.collectioName}>
                              <h4>
                                {x.title.length > 25
                                  ? x.title.slice(0, 25) + '....'
                                  : x.title}
                              </h4>
                              <p>@{x.createdBy ? x.createdBy : x.title}</p>
                            </div>
                          </div>
                          {/* <div className={styles.prices}> <img src={Polygon} className={styles.eth} alt="polygon" />
                          <div> <h4>{x.tokenPrice} MATIC</h4>
                            <p>$ {(value * x.tokenPrice).toFixed(2)}</p></div>
                        </div> */}
                        </div>
                      </div>
                    </div>
                    <Link
                      to={
                        x?.tokenStandard === 'ERC-1155'
                          ? '/collections/' + x._id
                          : '/collectionDetail/nfts/' + x._id
                      }
                    >
                      {/* <Link to={`/collections/${x._id}`}> */}
                      <button className={cn('button')}>Collect Now</button>
                    </Link>
                  </div>
                ))}
              </Slider>
            </div>
          )}
        </div>
      </>
      {/* <Collections props={props} title="Top NFTs" /> */}
      <div className={cn('container', styles.container)}>
        <h3 className={styles.heading} style={{ marginBottom: '100px' }}>
          Top NFTs
        </h3>

        {/* <div className={styles.nav}>
          {navLinks.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => {
                setActiveIndex(index);
                // filter(x);
                getNft(x.tags)
              }}
              key={index}
            >
              {x.x}
            </button>
          ))}
        </div> */}
        {loading ? (
          <Loader className={styles.loader} />
        ) : (
          <div className={styles.row}>
            {asset1
              .sort((a, b) => a.id - b.id)
              .slice(0, 8)
              .map((x, index) => (
                <Card className={styles.card} item={x} key={index} />
              ))}
          </div>
        )}

        <Link to="/collection">
          <button className={cn('button', styles.button1)}>Load more</button>
        </Link>
      </div>
      <div className={cn('container', styles.container)}>
        <h1 className={styles.updates}>Get More updates</h1>
        <p className={styles.feature}>
          Join our mailing list to stay in the loop with our newest feature
          releases, NFT drops, and tips and tricks{' '}
        </p>
        <div className={styles.email}>
          <TextInput
            placeholder="Your Email..."
            className={styles.input}
            onChange={(e) => {
              emailFunctions(e);
            }}
          />
          <button
            className={cn('button', styles.in)}
            onClick={() => {
              createUser();
            }}
          >
            I'm In {isLoading && <Spin />}
          </button>
        </div>
        {emailError && <p className={styles.errorMsg}>{emailError}</p>}
      </div>
    </div>
  );
};

export default Description;
