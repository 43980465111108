import React from "react";
import cn from "classnames";
import styles from "./Preview.module.sass";
import Icon from "../../../components/Icon";
import Bid from "../../../mocks/bids";

const Preview = ({ className, onClose,img ,title,description,stock,price}) => {
  return (
    <div className={cn(className, styles.wrap)}>
      <div className={styles.inner}>
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="14" />
        </button>
        <div className={styles.info}>Preview</div>
        <div className={styles.card}>
          <div className={styles.preview}>
            <img
              srcSet={img?img:"/images/content/card-pic-6.jpg"}
              src="/images/content/card-pic-6@2x.jpg"
              alt="Card"
            />
          </div>
          <div className={styles.link}>
            <div className={styles.body}>
              <div className={styles.line}>
                <div className={styles.title}>{title?title:"Black Golden Tiger"}</div>
                <div className={styles.price}>{price?price:"2.45"} {Bid}</div>
              </div>
              <div className={styles.line}>
                <div className={styles.users}>
                  <div className={styles.avatar}>
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcm5WldpI23UcsikhxKoz_ZpcyDS19ryItSw&usqp=CAU" alt="Avatar" />
                  </div>
                </div>
                
                <div className={styles.counter}>{stock?stock:"3"} in stock</div>
              </div>
            </div>
            <div className={styles.foot}>
              {/* <div className={styles.status}>
                <Icon name="candlesticks-up" size="20" />
                Highest bid <span>0.001 ETH</span>
              </div>
               */}
              <div className={styles.bid}>

              {description?description:
              <div>
                New bid
                <span role="img" aria-label="fire">
                  🔥
                </span></div>}
              </div>
            </div>
          </div>
        </div>
        {/* <button className={styles.clear}>
          <Icon name="circle-close" size="24"  />
          Clear all
        </button> */}
      </div>
    </div>
  );
};

export default Preview;
