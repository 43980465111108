import {
    GETPOSTS,
    GETUSERPOSTS,
    CLEARGETPOSTS,
    GETSEARCHPOSTS,
    GETLASTKEY,
    GETPOSTSLOADNIG,
    CLEARGETPOSTSLOADNIG,
    SETFIRSTLOADED,
    POST_CANCELLED,
    POST_ADDED,
    CREATE_POST,
    CREATE_POST_RESPONSE,
    CREATE_POST_ERROR,
    CREATE_POST_ERROR_CLEAR,
    EDIT_POST,
    EDIT_POST_ERROR,
    EDIT_POST_ERROR_CLEAR,
    EDIT_POST_RESPONSE,
    CLEARPOSTS
} from './PostsTypes'


import { GetAsset,GetSearchAsset, GetUserAssets } from '../../services/ApiServices'


// export const createPost = (payload) => {
//     return async (dispatch) => {
//         let result;
//         dispatch({ type: CREATE_POST })
//         try {
//             result = await addPost(payload);
//         }
//         catch (e) {
//             if (e.message.includes('token is expired or token is unauthorized, please login again')) {
//                 return
//             }
//             else {
//                 return dispatch(CreatePostsError(e.message))
//             }

//         }

//         if (result.success) {
//             dispatch(createPostsResponse(result.subscription))
//         }
//         else {
//             return dispatch(CreatePostsError(result.error))

//         }

//     }



// }

export const createPostsResponse = (payload) => {

    return (dispatch) => {
       

        return dispatch({
            type: CREATE_POST_RESPONSE,
            payload: payload
        })
    }
}



// export const getSearch = () => {


//     return async (dispatch) => {
//         let result;
//         dispatch({ type: SETFIRSTLOADED })
//         try {
//             result = await GetAsset();
//         }
//         catch (e) {

//             if (e.message.includes('token is expired or token is unauthorized, please login again')) {
//             }

//         }

//         if (result&&result.success) {
//             try{

//             dispatch(GetSearchPostsResponse(result.posts))
//             }
//             catch(e){}
//         }

//     }

// }

// export const getPosts = () => {


//     return async (dispatch) => {
//         let result;
//         dispatch({ type: SETFIRSTLOADED })
//         try {
//             result = await GetAsset();
//         }
//         catch (e) {

//             if (e.message.includes('token is expired or token is unauthorized, please login again')) {
//             }

//         }

//         if (result&&result.success) {
//             try{
//             dispatch(GetPostsResponse(result.posts))
//             dispatch(GetLastKeyResponse(result.lastKey))
//             }
//             catch(e){}
//         }

//     }

// }

export const getUserPosts = (id) => {


    return async (dispatch) => {
        let result;
        try {
            result = await GetUserAssets(id);
        }
        catch (e) {

            if (e.message.includes('token is expired or token is unauthorized, please login again')) {
            }

        }

        if (result&&result.success) {
            try{
            dispatch(GetUserPostsResponse(result.posts))
            }
            catch(e){}
        }

    }

}

export const GetPostsResponse = (payload) => {

    return (dispatch) => {

        return dispatch({
            type: GETPOSTS,
            payload: payload
        })
    }
}

export const GetUserPostsResponse = (payload) => {

    return (dispatch) => {

        return dispatch({
            type: GETUSERPOSTS,
            payload: payload
        })
    }
}
export const GetLastKeyResponse = (payload) => {

    return (dispatch) => {

        return dispatch({
            type: GETLASTKEY,
            payload: payload
        })
    }
}
export const GetSearchPostsResponse = (payload) => {

    return (dispatch) => {

        return dispatch({
            type: GETSEARCHPOSTS,
            payload: payload
        })
    }
}
export const CreatePostsError = (payload) => {

    return (dispatch) => {
        return dispatch({

            type: CREATE_POST_ERROR,
            payload: payload
        })
    }
}

export const clearPosts = () => {

    return (dispatch) => {
        return dispatch({

            type: CLEARPOSTS
        })
    }
}
