import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Collections.module.sass";
import Owl from "../../../images/Group 44.png";
import Duck from "../../../images/Group 48.png";
import Sculpture from "../../../images/Group 46.png";
import Woman from "../../../images/woman.png";
import Card from "../../../components/Card";
import { nfts } from "../../../data/nfts";
import { useParams } from "react-router";
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIosNew } from "react-icons/md"
import ReactPaginate from "react-paginate";
import "./index.css"
import { Pagination } from "antd";
import { GetTopNfts, GetTopNftsByTags } from "../../../services/ApiServices";
import Loader from "../../../components/Loader";

const Collections = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [viewAllAsset, setViewAllAssets] = useState(false);
  const { id } = useParams();
  const [assetss, setAssets] = useState([]);
  const [asset1, setAsset1] = useState([]);
  const [allAssets, setAllAssets] = useState([]);
  // const [page, setPage] = useState(0);
  const [items, setItems] = useState(nfts);
  const [itemsPerPage] = useState(8)
  const [page, setPage] = useState(1);
  const [current, setCurrent] = useState(1)
  const [totalDocs, setTotalDocs] = useState(0)
  const [loading, setLoading] = useState(false)
  const [item, setItem] = useState()
  const [limit, setLimit] = useState(8)
  const [tags ,setTags] = useState("AR Backgrounds")
  const numberOfItemsVisited = page * itemsPerPage
  const totalPages = Math.ceil(assetss.length / itemsPerPage);
  const asset = [
    {
      image: Owl,
      collectionName: "Owel illustrate",
      name: "@PROOF_XYZ",
      floorPrice: "388.29",
      price: "345.12",
      volume: "+12.45%",
    },
    {
      image: Duck,
      collectionName: "Owel illustrate",
      name: "@PROOF_XYZ",
      floorPrice: "388.29",
      price: "345.12",
      volume: "+12.45%",
    },
    {
      image: Sculpture,
      collectionName: "Owel illustrate",
      name: "@PROOF_XYZ",
      floorPrice: "388.29",
      price: "345.12",
      volume: "+12.45%",
    },
    {
      image: Woman,
      collectionName: "Owel illustrate",
      name: "@PROOF_XYZ",
      floorPrice: "388.29",
      price: "345.12",
      volume: "+12.45%",
    },
    {
      image: Owl,
      collectionName: "Owel illustrate",
      name: "@PROOF_XYZ",
      floorPrice: "388.29",
      price: "345.12",
      volume: "+12.45%",
    },
    {
      image: Duck,
      collectionName: "Owel illustrate",
      name: "@PROOF_XYZ",
      floorPrice: "388.29",
      price: "345.12",
      volume: "+12.45%",
    },
    {
      image: Sculpture,
      collectionName: "Owel illustrate",
      name: "@PROOF_XYZ",
      floorPrice: "388.29",
      price: "345.12",
      volume: "+12.45%",
    },
    {
      image: Woman,
      collectionName: "Owel illustrate",
      name: "@PROOF_XYZ",
      floorPrice: "388.29",
      price: "345.12",
      volume: "+12.45%",
    },
  ];

  const navLinks = [
    { x: "AR Backgrounds", tags: "AR Backgrounds" },
    { x: "AR Avatars", tags: "AR Avatars" },
    { x: "Face Swaps", tags: "Face Swaps" },
    { x: "Filters", tags: "Filters" },
    { x: "SETVI", tags: "Setvi" },
  ];

  const displayItems = assetss
    .slice(
      numberOfItemsVisited,
      numberOfItemsVisited + itemsPerPage
    )
    .map((x, index) => {
      return (
        <Card className={styles.card} item={x} key={index} />
      );
    });

  const changePage = ({ selected }) => {
    // setPage(selected);
  };

  const filter = (value) => {
    let asset = [];
    if (value !== "SETVI") {
      allAssets.map((data) => {
        data.tags.map((tag) => {
          if (tag === value) {
            asset.push(data);
          }
          console.log(data);
        });
      });
      setAssets(asset);
    } else {
      setAssets(allAssets);
    }
  };

  const getNftByTags = async (tags) => {

    let result;
    try {
      result = await GetTopNftsByTags(tags);
    } catch (e) {
      console.log(e);

    }
    try {
      if (result.success) {
        setAsset1(result.asset.docs);
        // setPage(result.asset.nextPage)
        setTotalDocs(result.asset.totalDocs)
        // console.log(page,limit,totalDocs)
      }
    } catch (e) {
      console.log(e);
    }
  };
  // useEffect(() => {
  //   setAssets(nfts);
  //   setAllAssets(nfts);
  // }, [nfts]);

  const getNfts = async (page, limit) => {
    setLoading(true)
    let result;
    try {
      result = await GetTopNfts(page, limit);
    } catch (e) {
      console.log(e);

    }
    try {
      if (result.success) {
        setLoading(false)
        setAsset1(result.asset.docs);
        setItem(result.asset)
        setPage(result.asset.nextPage)
        setTotalDocs(result.asset.totalDocs)
        console.log(page, "explore", totalDocs, result.asset.totalDocs)
      }
    } catch (e) {
      setLoading(false)
      console.log(e);
    }
  };

  const loadMore = (page, limit) => {
    setCurrent(page)
    getNfts(page, limit)
  }

  useEffect(() => {
    getNftByTags("AR Backgrounds")
  }, []);


  return (
    <div className={cn("container", styles.container)}>
      <h3 className={styles.title1}>Explore</h3>

      <div className={styles.nav}>
        {navLinks.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => {
              setActiveIndex(index);
              getNftByTags(x.tags)
              // setTags(x.tags)
            }}
            key={index}
          >
            {x.x}
          </button>
        ))}
      </div>
      {loading ? <Loader className={styles.loader} /> :
        <div className={styles.row}>
          {asset1.map((x, index) =>
            <Card className={styles.card} item={x} key={index} />
          )}
          {/* {displayItems} */}
        </div>}
      <>
        {/* <ReactPaginate
          previousLabel={<MdOutlineArrowBackIosNew />}
          nextLabel={<MdOutlineArrowForwardIos />}
          pageCount={totalPages}
          onPageChange={changePage}
          containerClassName={"navigationButtons"}
          previousLinkClassName={"previousButton"}
          nextLinkClassName={"nextButton"}
          disabledClassName={"navigationDisabled"}
          activeClassName={"navigationActive"}
        /> */}
        <div className={styles.pagination}>
          <Pagination current={current} onChange={loadMore} total={totalDocs} defaultPageSize={8} />
        </div>
      </>
    </div>

  );

};

export default Collections;
