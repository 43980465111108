/* eslint-disable import/no-unresolved */
import { useEffect, useState, useRef } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactContextInterface } from '@web3-react/core/dist/types';
import { simpleRpcProvider } from '../utils/providers';

const useActiveWeb3React = (): Web3ReactContextInterface<Web3Provider> => {
  const { library, chainId, ...web3React } = useWeb3React();
  const refEth = useRef(library);
  const [provider, setProvider] = useState(library)// || simpleRpcProvider);

  useEffect(() => {
    console.log("provider changed", library);
      console.log("refEth", refEth.current);
    if (library !== refEth.current) {
      console.log("provider changed", library);
      console.log("refEth", refEth.current);
      setProvider(library)//|| simpleRpcProvider);
      refEth.current = library;
    } else {
      console.log("No provider change");
    }
  }, [library]);

  return {
    library: provider,
    chainId,
    ...web3React,
  };
};

export default useActiveWeb3React;
