/* eslint-disable no-unused-vars */
import {parseUnits} from 'ethers/lib/utils';
import {utils} from "ethers";

export const minifyAddress = (address: string, size: number) =>
  `${address.slice(0, size || 6)}...${address.slice(-size || -6, address.length)}`;

// eslint-disable-next-line no-shadow
export enum GAS_PRICE {
  Default = '5',
  Fast = '6',
  Instant = '7',
  Testnet = '10',
}

export const GAS_PRICE_GWEI = {

  default: parseUnits(GAS_PRICE.Default, 'gwei').toString(),
  fast: parseUnits(GAS_PRICE.Fast, 'gwei').toString(),
  instant: parseUnits(GAS_PRICE.Instant, 'gwei').toString(),
  testnet: parseUnits(GAS_PRICE.Testnet, 'gwei').toString(),
};


export const toDisplay = (balance: number) => {
  let value = utils.formatEther(balance?.toString())
  return Math.round(Number(value) * 1e4) / 1e4
}

