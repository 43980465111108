import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Discover3.module.sass";
import { Range, getTrackBackground } from "react-range";
import Slider from "react-slick";
import Icon from "../../../components/Icon";
import Card from "../../../components/Card";
import Card3 from "../../../components/Card3/index";


import Dropdown from "../../../components/Dropdown";
import {
  GetAssetpaginate,
  GetPostByTags,
} from "../../../services/ApiServices";
import LoaderCircle from "../../../components/LoaderCircle";
import Loader from "../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
// import { getPosts } from "../../../redux";
import Bid from "../../../mocks/bids";
import {Posts} from "../../../json/Assets"

// const navLinks = ["All items","Equity", "Bond", "Crowd funding", "Derivatives", "NFT"];

const dateOptions = ["Recently added", "Long added"];
const priceOptions = ["Highest price", "The lowest price"];
const likesOptions = ["Most liked", "Least liked"];
const creatorOptions = ["Verified only", "All", "Most liked"];
const sortingOptions = [];
// navLinks.map((x) => sortingOptions.push(x));

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Discover3 = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const [activeIndex, setActiveIndex] = useState(0);
  const [date, setDate] = useState(dateOptions[0]);
  const [price, setPrice] = useState(priceOptions[0]);
  const [likes, setLikes] = useState(likesOptions[0]);
  const [creator, setCreator] = useState(creatorOptions[0]);
  const [sorting, setSorting] = useState(sortingOptions[0]);
  const [Assets, setAssets] = useState([]);
  const [AllAssets, setAllAssets] = useState([]);
 
    
  const [lastKey, setLastkey] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState([5]);
  const [searchvalue,setSearchValue] = useState(getQueryVariable('search'));
  const [visible, setVisible] = useState(false);
  
  const STEP = 0.1;
  const MIN = 0.01;
  const MAX = 10;
  
 
  
  // if (state.Posts.isFirstLoaded === false) {
  //   dispatch(getPosts());
  // }
  


  function getQueryVariable(variable)
  {
          var query = window.location.search.substring(1);
          var vars = query.split("&");
          for (var i=0;i<vars.length;i++) {
                      var pair = vars[i].split("=");
          if(pair[0] === variable){return pair[1];}
           }
           return(false);
  }
  const SearchFunction=()=>{
  if(getQueryVariable('search')){
    let searchvalue=getQueryVariable('search');
    setAssets(state.Posts.searchposts.filter(data => {
      return data.title
          .toUpperCase()
          .includes(
            searchvalue.toUpperCase()
          );
  }))
  }
else{
  setAssets(state.Posts.posts)
}}
  useEffect(()=>{
    SearchFunction()
    setSearchValue(getQueryVariable('search'));
  },[searchvalue]);

  // const getPostByTags = async (tag) => {
  //   if (tag === "All items") {
  //     setAssets(state.Posts.posts);
  //   } else {
  //     setIsLoading(true);
  //     setAssets([]);

  //     let result;
  //     try {
  //       result = await GetAsset(tag);
  //     } catch (e) {
  //       console.log(e);
  //     }

  //     try {
  //       if (result.success) {
  //         setIsLoading(false);
  //         setAssets(result.asset);
  //         setLastkey(result.lastKey);
  //       }
  //     } catch (e) {
  //       setIsLoading(false);
  //       console.log(e);
  //     }
  //   }
  // };
  // useEffect(() => {
  //   getAssetspaginate()
  // },[])
  
  // console.log(GetAsset)
  // const getAssetspaginate = async () => {
  //   setIsLoading(true);

  //   let result;
  //   try {
  //     result = await GetAsset();
  //     console.log("Assetresult",result)
      
  //     if (result&&result.success) {
  //       setIsLoading(false);

  //       // result.posts.map((data) => {
  //       //   setAssets((assets) => assets.concat(data));
  //       // });
  //       setAssets(result.asset);
  //       console.log(result.asset)
  //     }
  //   } 
  //   catch (e) {
  //     console.log(e);
  //   }

  //   // try {
  //   //   if (result.success) {
  //   //     setIsLoading(false);

  //   //     // result.posts.map((data) => {
  //   //     //   setAssets((assets) => assets.concat(data));
  //   //     // });
  //   //     setAssets(result.assetss);
  //   //     console.log(result.assetss)
  //   //   }
  //   // } catch (e) {
  //   //   setIsLoading(false);

  //   //   console.log(e);
  //   // }
  // };
  // useEffect(() => {
  //   setAssets(state.Posts.posts);
  // }, [state.Posts.posts.length]);

  // useEffect(() => {
  //   setLastkey(state.Posts.lastKey);
  // }, [state.Posts.lastKey]);


const filter=(value)=>{
    // getAssetspaginate()
  let assets = []
  if(value!="All items"){

  
  Assets.map(data => {
    data.tags.map(tag => {
       
if(tag==value){
  assets.push(data)
}
console.log(data);  
    })
  })
  setAssets(assets)
  // getAssetspaginate()
}
else{
  setAssets(Assets)
  // getAssetspaginate()
}
}
console.log(Assets)

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 100000,
        settings: "unslick",
      },
    ],
  };

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <h3 className={cn("h3", styles.title)}>Explore</h3>
        <div className={styles.top}>
          {/* <div className={styles.dropdown}>
             <Dropdown
              className={styles.dropdown}
              value={date}
              setValue={filter}
              options={dateOptions
              //   .map(x)=>(
              //   onclick={()=>{}}
              // )
             }
            />
          </div> */}
          {/* <div className={styles.nav}> */}
            {/* {navLinks.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: index === activeIndex,
                })}
                onClick={() =>{setActiveIndex(index); filter(x)}}
                key={index}
              >
                {x}
              </button>
            ))} */}
          {/* </div> */}
          {/* <div className={cn("tablet-show", styles.dropdown)}>
            <Dropdown
              className={styles.dropdown}
              value={sorting}
              setValue={filter}
              options={sortingOptions}
            />
          </div> */}
          {/* <button
            className={cn(styles.filter, { [styles.active]: visible })}
            onClick={() => setVisible(!visible)}
          >
            <div className={styles.text}>Filter</div>
            <div className={styles.toggle}>
              <Icon name="filter" size="18" />
              <Icon name="close" size="10" />
            </div> 
          </button> */}
        </div>
          <div className={cn(styles.filters, { [styles.active]: visible })}>
          <div className={styles.sorting}>
            <div className={styles.cell}>
              <div className={styles.label}>Price</div>
              <Dropdown
                className={styles.dropdown}
                value={price}
                setValue={filter}
                options={priceOptions}
              />
            </div>
            <div className={styles.cell}>
              <div className={styles.label}>likes</div>
              <Dropdown
                className={styles.dropdown}
                value={likes}
                setValue={filter}
                options={likesOptions}
              />
            </div>
            <div className={styles.cell}>
              <div className={styles.label}>creator</div>
              <Dropdown
                className={styles.dropdown}
                value={creator}
                setValue={filter}
                options={creatorOptions}
              />
            </div>
            <div className={styles.cell}>
              <div className={styles.label}>Price range</div>
              <Range
                values={values}
                step={STEP}
                min={MIN}
                max={MAX}
                onChange={(values) => setValues(values)}
                renderTrack={({ props, children }) => (
                  <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{
                      ...props.style,
                      height: "27px",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      ref={props.ref}
                      style={{
                        position: "absolute",
                        top: "20px",
                        height: "8px",
                        width: "100%",
                        borderRadius: "4px",
                        backgroundColor:"#3cc5c5"
                        
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props, isDragged }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "24px",
                      width: "24px",
                      borderRadius: "50%",
                      backgroundColor:"#3cc5c5",
                      border: "4px solid #FCFCFD",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                    className={styles.title12345}
                      style={{
                        position: "absolute",
                        top: "-33px",
                        color: "#fff",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "18px",
                        padding: "4px 8px",
                        borderRadius: "8px",
                        backgroundColor: "#141416",
                      }}
                    >
                      {values[0].toFixed(1)}
                    </div>
                  </div>
                )}
              />
              {/* <div className={styles.scale}>
                <div className={styles.number}>0.01 {Bid}</div>
                <div className={styles.number}>10 {Bid}</div>
              </div> */}
            </div>
          </div>
        </div>
        <div className={styles.list}>
          <Slider
            className={cn("discover-slider", styles.slider)}
            {...settings}
          >
             {/* {Assets.slice(0,8).map((x, index) => (  */}
             {Assets.map((x, index) => (
              
              <Card className={styles.card} item={x} key={index} />
            ))}
             </Slider>

             {/* <Slider
              className={cn("discover-slider", styles.slider)}
            {...settings}
          >
             {Assets.slice(8,9).map((x, index) => ( 
            // {Assets.map((x, index) => (
              
              <Card3 className={styles.card} item={x} key={index} />
            ))}
          </Slider> */}
        </div>
        {!isLoading && Assets.length <= 0 && (
          <h2 className={styles.nopost}>No posts Available</h2>
          
        )}
        <div className={styles.btns}>
          {/* {(state.Posts.postLoading || isLoading) && (
            <Loader className={styles.loader} />
          )} */}
          
           
          {state.Posts.isFirstLoaded === true && (
            <button
              className={cn("button-stroke button-small", styles.button)}
              
            >
              <span >Load more</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Discover3;
